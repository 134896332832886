/* eslint-disable import/first */
import React from 'react';
import ReactDOM from 'react-dom';
import '../css/index.css';
import App from './App';

window.openContactWidget = () => {
  if (window.HubSpotConversations) window.HubSpotConversations.widget.open();
};

window._hsp = window._hsp || [];

window._hsp.push(['addPrivacyConsentListener', function (consent) {
  if (consent.categories.analytics) {
    // Load Google Analytics
    const gtagUa = 'UA-127417257-1';

    const s = document.createElement('script');
    s.src = `https://www.googletagmanager.com/gtag/js?id=${gtagUa}`;
    s.async = true;
    document.getElementsByTagName('head')[0].appendChild(s);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() { window.dataLayer.push(arguments); };
    gtag('js', new Date());
    gtag('config', gtagUa, { anonymize_ip: true });
  }
}]);

ReactDOM.render(<App />, document.getElementById('root'));

if (module.hot) {
  module.hot.dispose(() => {});
  module.hot.accept(() => {});
}
