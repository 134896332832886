import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1px 4px',
    fontWeight: 700,
    borderRadius: '5px',
  },
  success: {
    color: theme.palette.primary.main,
  },
  warning: {
    color: theme.palette.error.light,
  },
  error: {
    color: theme.palette.error.main,
  },
  dark: {
    backgroundColor: theme.palette.background.paper,
  },
  light: {
    backgroundColor: theme.palette.background.default,
  },
}));

const ShortCode = (props) => {
  const { children, type, light } = props;
  const classes = useStyles();
  return (
    <span className={clsx(classes[type] || null, light ? classes.light : classes.dark, classes.root)}>{children}</span>
  );
};

ShortCode.defaultProps = {
  type: 'info',
  light: false,
};

export default ShortCode;
