import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import ApiSection from '../../../UX/ApiSection';
import Note from '../../../UX/Note';
import apiVideo from '../../../../../assets/videos/danim-motion-api.mp4';

function Introduction() {
  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <ApiSection 
          title="Introduction"
          href="introduction"
          className="introductionSection"
          description={(
            <React.Fragment>
              From the creation of personalized videos for your users and clients to the large-scale marketing campaign, our API is an easy way to achieve your goals with automated video creation.
              <br /><br />
              The Danim API is an interface for reading and writing data from and to client online accounts, 
              organizations that holds them, videos having been or being generated, and so on.
            </React.Fragment>
          )}
        >
          <Typography variant="body2" component="span">
            The web API URLs follow the following forms:
            <ul>
              <li>https://api.danim.com/write/<span className="colorLight">{'{DOMAIN}'}</span>/<span className="colorLight">{'{COMMAND}'}</span> for writes</li>
              <li>https://api.danim.com/read/<span className="colorLight">{'{DOMAIN}'}</span>/<span className="colorLight">{'{QUERY}'}</span> for reads</li>
            </ul>
            Please note that Danim API is not a REST API, hence all requests are passed through POST method (you’ll not see methods such as DELETE, PATCH, PUT, etc.). 
            Our read API is built with GraphQL and <a href="#reference-read">GraphiQL</a> is available in this documentation to visually explore our read API. 
          </Typography>
          <Note status="info" className="mt-2" title="Want to try our API?">
            Please <a onClick={() => window.openContactWidget()} href="javascript:void(0)">contact us</a> if you have any question or feature request!
          </Note>
        </ApiSection>
      </Grid>
      <Grid item xs={12} md={4}>
        <video className="apiVideo autoSticky" src={apiVideo} loop muted autoPlay />
      </Grid>
    </Grid>
  );
}

export default Introduction;
