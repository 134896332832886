import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Icon } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  spanTitle: {
    position: 'relative',
    bottom: '5px',
  },
  warning: {
    color: theme.palette.error.light,
  },
  success: {
    color: theme.palette.primary.main,
  },
  info: {
    color: theme.palette.text.secondary,
  },
  light: {
    backgroundColor: theme.palette.background.default,
  },
}));

function Note(props) {
  const { status, title, className, children, light } = props;
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root, className, light && classes.light)}>
      <CardContent>
        {
              (status || title) && (
                <Typography className={classes[status]} gutterBottom>
                  {
                        status === 'warning' ? <Icon className="mr-05">warning</Icon>
                          : status === 'info' ? <Icon className="mr-05">info</Icon>
                            : null
                    }
                  { title && <span className={classes.spanTitle}>{title}</span>} 
                </Typography>
              )
          }
        { children }
      </CardContent>
    </Card>
  );
}

export default Note;
