import React from 'react';
import { Typography } from '@material-ui/core';
import ApiSection from '../../../../UX/ApiSection';
import ShortCode from '../../../../UX/ShortCode';

function RateLimits() {
  return (
    <ApiSection 
      title="Rate limits"
      href="rate-limits"
    >
      <Typography variant="body2">
        Be aware of the fact that the amount of requests you may perform is limited in time 
        (based upon your API credentials), with a precise per-minute quota. 
        <br />This amount may be discussed, feel free to <a onClick={() => window.openContactWidget()} href="javascript:void(0)">contact us</a>.
        <br /><br />
        If your request is overflowing the quota, you’ll get a failure (<ShortCode type="warning">success: false</ShortCode>) 
        with <ShortCode type="warning">meta.type: RateLimitExceeded</ShortCode> 
        (<a href="#evaluating-responses">see response details above</a>).
      </Typography>
    </ApiSection>
  );
}

export default RateLimits;
