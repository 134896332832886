import React, { useMemo } from 'react';
import { get } from 'lodash-es';
import Table from '../../../../UX/Table';
import Markdown from '../../../../UX/Markdown';

function ApiReferenceErrorsTable(props) {
  const { errorSchema } = props;
  const errorsTable = useMemo(() => {
    const metaSchema = get(errorSchema, 'meta.properties.type');
    if (metaSchema) {
      const { enum: codes, 'x-enum-description': descriptions } = metaSchema;
      if (codes && descriptions) {
        const table = {
          columns: [{
            id: 'code',
            label: 'Error code',
          }, {
            id: 'description',
            label: 'Error description',
          }],
          rows: [],
        };
        codes.forEach(code => {
          const description = descriptions[code];
          if (description) {
            table.rows.push({
              code,
              description: <Markdown content={description} className="errorsMarkdown" />, 
            });
          }
        });
        if (table.rows.length > 0) return table;
      }
    } 
    return null;
  }, [errorSchema]);

  return errorsTable && (
    <Table 
      table={errorsTable} 
      className="mt-2" 
      title={<span className="colorError w-bold">Errors details</span>}
    />
  );
}

export default ApiReferenceErrorsTable;
