import React, { useMemo } from 'react';
import clsx from 'clsx';
import CodeBlock from '../../../../UX/CodeBlock';
import objectToArray from '../../../../../utilities/objectToArray';

function ApiReferenceResponsesExamples(props) {
  const { examples: _examples } = props;

  const examples = useMemo(() => {
    const result = [];
    const examplesArr = objectToArray(_examples);
    examplesArr.forEach(example => {
      const parts = example.id.split(':');
      if (parts.length > 1 && parts[0] === '1') {
        example.id = parts[1].trim();
      }
      result.push(example);
    });
    return result;
  }, [_examples]);

  return (
    <div className="mb-2">
      {
        examples.map((example, index) => (
          <CodeBlock 
            key={`${example.id}_${index}`} 
            language="json" 
            title={(
              <React.Fragment>
                Response:
                <span 
                  className={
                    clsx(
                      'ml-05',
                      'w-bolder', 
                      example.id === 'success' && 'colorLight', 
                      example.id === 'error' && 'colorError', 
                      example.id === 'warning' && 'colorWarning',
                    )
                  }
                >
                  {example.id}
                </span>
              </React.Fragment>
            )}
          >
            {JSON.stringify(typeof example.value === 'string' || example.value instanceof String ? JSON.parse(example.value) : example.value, null, 2)}
          </CodeBlock>
        ))
      }
    </div>
  );
}

export default ApiReferenceResponsesExamples;
