/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react';
import { } from '@material-ui/core';
import objectToArray from '../../../../../utilities/objectToArray';
import ApiReferenceAttributesItem from './ApiReferenceAttributesItem';

function ApiReferenceAttributes(props) {
  const { schema, className, hideDivider } = props;
  const { properties, required } = schema;
  
  const parameters = useMemo(() => {
    const _parameters = objectToArray(properties);
    if (required) {
      _parameters.forEach(param => {
        if (typeof required === 'object'
            && Object.prototype.toString.call(required) === '[object Array]'
            && required.indexOf(param.id) !== -1
        ) param.isRequired = true;
      });
    }
    return _parameters;
  }, [schema]);

  return (
    <div className={className || null}>
      {
          parameters.map((param, index) => (
            <ApiReferenceAttributesItem 
              key={`${param.id}_${index}`} 
              item={param}
              hideDivider={hideDivider || (index === parameters.length - 1)}
            />
          ))
        }
    </div>
  );
}

export default ApiReferenceAttributes;
