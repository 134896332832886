import { Typography, Link } from '@material-ui/core';
import React from 'react';

function Home() {
  return (
    <div className="vertical-center">
      <div className="mb-2">
        <Typography variant="h3" align="center" className="colorSecondary">Welcome to Danim docs</Typography>
        <Typography variant="h5" align="center" className="colorWhite">Where do you want to go?</Typography>
      </div>
      <div className="mt-1">
        <Typography align="center" className="colorSecondary">I'm a motion designer, <Link href="/creators">let's go to Creator docs</Link></Typography>
      </div>
      <div className="mt-1">
        <Typography align="center" className="colorSecondary">I'm a developer, <Link href="/api">send me to API docs</Link></Typography>
      </div>
    </div>
  );
}

export default Home;
