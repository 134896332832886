import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import CreatorsSection from '../../../UX/CreatorsSection';
import creatorsVideo from '../../../../../assets/videos/gif-hundreds.mp4';

function Introduction() {
  return (
    <CreatorsSection 
      title="Introduction"
      href="introduction"
      className="introductionSection"
      description={(
        <Grid container>
          <Grid item xs={12} md={8} className="mb-2">
            Welcome to our motion designer documentation. You will find here all the learning resources for Danim Creator and the related tools like Danim Box.
            <br /><br />
            All our video tutorials can be watched on our <a href="https://www.youtube.com/channel/UCt_N4hyFsN4vtmXFvsUKdpA" target="_blank" rel="noreferrer">Youtube channel</a>.
            <div className="mt-2 pt-2">
              <Typography variant="body2" component="p">
                If you can't find the answer to any of your questions, please tell us about it on <a href="https://discord.gg/J8bH8Xq" target="_blank" rel="noreferrer">discord</a> or <a onClick={() => window.openContactWidget('')} href="javascript:void(0)">contact us here</a>.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <video className="creatorsVideo autoSticky" src={creatorsVideo} loop muted autoPlay />
          </Grid>
        </Grid>
          )}
    />
  );
}

export default Introduction;
