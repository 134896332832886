import React from 'react';
import { Stepper, Step, StepLabel, StepContent, Link } from '@material-ui/core';
import CreatorsSection from '../../../../UX/CreatorsSection';
import step1 from '../../../../../../assets/images/creators/upload-template/upload-template-step1.jpg';
import step2 from '../../../../../../assets/images/creators/upload-template/upload-template-step2.jpg';
import step3 from '../../../../../../assets/images/creators/upload-template/upload-template-step3.jpg';
import Note from '../../../../UX/Note';

function UploadTemplate() {
  return (
    <CreatorsSection 
      title="Upload a template to danim.com"
      href="upload-a-template"
      isSubSection
      hideDivider
      description={(
        <React.Fragment>
          Once your template is finished, you can upload it on danim.com and use your forms from any web browser. It's easy, in 3 steps.
        </React.Fragment>
      )}
    > 
      <Stepper orientation="vertical" className="createVideoGuideSection">
        <Step active>
          <StepLabel className="stepLabel">Upload your template from Danim Creator</StepLabel>
          <StepContent>
            <img className="uploadTemplateStepImg mb-2" src={step1} />
            From the template builder <strong>(tab 3)</strong>, click on <strong>"Upload to danim.com"</strong>. You can then choose between updating an existing template or creating a new one. Define the title of your template and click on <strong>"Create"</strong>. Your template is uploaded in a few seconds on danim.com, and will be available from any computer on which you use Danim Creator.
          </StepContent>
        </Step>
        <Step active>
          <StepLabel className="stepLabel">Enable "Bot" mode on Danim Creator</StepLabel>
          <StepContent>
            <img className="uploadTemplateStepImg" src={step2} />
            From the video generator <strong>(tab 4)</strong>, click on <strong>"Enable rendering from danim.com"</strong>. This way, danim.com will be able to use your computer to render and upload your videos. 
          </StepContent>
          <Note status="info" className="mt-2" title="Install FFmpeg" light>
            FFmpeg has to be installed in your setup to transcode videos sent to danim.com. Go to Danim Creator settings (tab 5) to install it.
          </Note>
        </Step>
        <Step active>
          <StepLabel className="stepLabel">Go to danim.com</StepLabel>
          <StepContent>
            <img className="uploadTemplateStepImg mb-2" src={step3} />
            Connect to <Link href="https://app.danim.com" target="_blank">app.danim.com</Link> to find your template online and click on its thumbnail to use the web form. Fill in your form and click on the <strong>"Generate"</strong> button to start rendering your video.
            <br /><br />
            Your web forms are now available from any device. Danim is also collaborative! Thanks to premium subscriptions, you can directly invite your team or your customers directly, and distribute them over several workspaces.
          </StepContent>
        </Step>
      </Stepper> 
    </CreatorsSection>
  );
}

export default UploadTemplate;
