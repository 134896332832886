import React, { useContext } from 'react';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Waypoint } from 'react-waypoint';
import clsx from 'clsx';
import ApiSectionsContext from '../../utilities/contexts/ApiSectionsContext';

const useStyles = makeStyles((theme) => ({
  topSection: {
    padding: '100px 10% 0 10%',
  },
  subSection: {
    paddingTop: '50px',
  },
  subSectionTitle: {
    paddingBottom: '10px',
  },
  topSectionTitle: {
    paddingBottom: '40px',
  },
  darkTitle: {
    color: theme.palette.text.secondary,
  },
  lightTitle: {
    color: theme.palette.primary.light,
  },
  description: {
    color: theme.palette.text.secondary,
    paddingBottom: '20px',
  },
  divider: {
    marginTop: '50px',
  },
  debugWaypoint: {
    height: '100px',
    background: 'red',
  },
}));

function ApiSection(props) {
  const { children, title, description, href, isSubSection, lightTitle, hideDivider, className } = props;
  const context = useContext(ApiSectionsContext);
  const classes = useStyles();

  const handleWayPointEnter = () => {
    const { onWayPointEnter } = context;
    onWayPointEnter(href);
  };

  const handleWayPointLeave = () => {
    const { onWayPointLeave } = context;
    onWayPointLeave(href);
  };

  return (
    <div className={clsx(isSubSection ? classes.subSection : classes.topSection, className)} id={href || null}>
      <Typography variant={isSubSection ? 'h5' : 'h4'} className={clsx(lightTitle ? classes.lightTitle : classes.darkTitle, isSubSection ? classes.subSectionTitle : classes.topSectionTitle)}>
        { title }
      </Typography>
      {
        href && <Waypoint onEnter={handleWayPointEnter} onLeave={handleWayPointLeave} />
      }
      { description && (
        <Typography variant="body1" className={classes.description} component="div">
          {description}
        </Typography>
      )}
      {children}
      { !hideDivider && <Divider className={classes.divider} variant="middle" /> }
    </div>
  );
}

export default ApiSection;
