import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import Home from './components/Routes/Home/Home';
import Api from './components/Routes/Api/Api';
import Creators from './components/Routes/Creators/Creators';
import theme from './styles/theme';
import ErrorCatcher from './components/ErrorCatcher';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
  },
}));

function App() {
  const classes = useStyles();
  return (
    <ErrorCatcher>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <div className={classes.page}>
          <Router>
            <Switch>
              <Route path="/api"> 
                <Api />
              </Route>
              <Route path="/creators"> 
                <Creators />
              </Route>
              <Route exact path="*" component={Home} />
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    </ErrorCatcher>
  );
}

export default App;
