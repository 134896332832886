import { find } from 'lodash-es';
import apiReference from './apiReference';

const referenceList = [];
apiReference.forEach(reference => {
  const { domain, route, id } = reference;
  const item = { id, label: route };
  const domainInList = find(referenceList, { label: domain });
  if (!domainInList) {
    referenceList.push({
      id: `reference-group-${domain}`,
      label: domain,
      list: [item],
    });
  } else domainInList.list.push(item);
});
referenceList.push({
  id: 'reference-read',
  label: 'GraphQL',
});

const navList = [
  {
    id: 'introduction',
    label: 'Introduction',
  },
  {
    id: 'basics',
    label: 'Basics',
    list: [
      {
        id: 'content-type',
        label: 'Content-Type',
      },
      {
        id: 'evaluating-responses',
        label: 'Evaluating responses',
      },
      {
        id: 'rate-limits',
        label: 'Rate limits',
      },
    ],
  },
  {
    id: 'auth',
    label: 'Authentication',
  },
  {
    id: 'file-sending',
    label: 'File sending',
  },
  {
    id: 'reference',
    label: 'API reference',
    list: referenceList,
  },
  {
    id: 'guides',
    label: 'Guides',
    list: [
      {
        id: 'read-a-template-and-create-a-video',
        label: 'Read a template and create a video',
      },
    ],
  },
];
  
export default navList;
