import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { 
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: '700 !important',
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.text.secondaryTransparent}`,
  },
  noDivider: {
    borderBottom: 'none',
  },
  title: {
    padding: '16px',
  },
}));

function Table(props) {
  const classes = useStyles();
  const { table, caption, className, title } = props;
  const { columns, rows } = table;
  return (
    <TableContainer className={className || ''} component={Paper}>
      {
        title && (
          <React.Fragment>
            <Typography variant="h6" className={classes.title}>{title}</Typography>
            <Divider />
          </React.Fragment>
        )
      }
      <MuiTable>
        { caption && <caption className={classes.bold}>{caption}</caption> }
        <TableHead>
          <TableRow className={classes.bold}>
            {
                  columns.map(column => (
                    <TableCell className={clsx(classes.divider, classes.bold)} key={column.id}>{column.label}</TableCell>
                  ))
              }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {
                  columns.map((column) => (
                    <TableCell className={!caption && index === rows.length - 1 ? classes.noDivider : classes.divider} component="th" scope="row" key={column.id}>
                      {row[column.id]}
                    </TableCell>
                  ))
              }
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

export default Table;
