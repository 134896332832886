import React from 'react';
import ReactMarkdown from 'react-markdown';
import ShortCode from './ShortCode';

const renderers = {
  inlineCode: (props) => <ShortCode {...props} />,
};
  
function Markdown(props) {
  const { content, className } = props;

  return (
    <ReactMarkdown
      className={className || null}
      source={content}
      renderers={renderers}
    />
  );
}

export default Markdown;
