/* eslint-disable import/no-cycle */
import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ShortCode from '../../../../UX/ShortCode';
import ApiReferenceAttributes from './ApiReferenceAttributes';
import Markdown from '../../../../UX/Markdown';

const useStyles = makeStyles(() => ({
  description: {
    fontSize: '0.75rem',
    marginTop: '5px',
  },
}));

function ApiReferenceAttributesItem(props) {
  const classes = useStyles();
  const { item, hideDivider } = props;
  const { id, type, description, isRequired, properties, format } = item;

  return (
    <div className="pt-1">
      <span className="mr-1">
        <ShortCode light>{id}</ShortCode>
        {isRequired && <span className="colorError w-bolder"> *</span>}
      </span>
      <Typography variant="caption" className="colorSecondary">
        {format === 'file' ? format : type}
        {item.enum && `: ${item.enum.join(' | ')}`}
      </Typography>
      {
          description && (
            <div>
              <Typography variant="body2" className={classes.description} component="div">
                <Markdown content={description} />
              </Typography>
            </div>
          )
      }
      {
          (!isRequired && typeof item.default !== 'undefined') && (
            <div>
              <Typography variant="overline">Default: </Typography>
              <Typography variant="caption" className="colorSecondary">{item.default || 'null'}</Typography>
            </div>
          )
      }
      {
          properties && Object.keys(properties).length > 0 ? (
            <ApiReferenceAttributes schema={item} hideDivider className="pl-2 mt-1" />
          ) : null
      }
      {
          !hideDivider && <Divider className="mt-1 mb-1" />
      }
    </div>
  );
}

export default ApiReferenceAttributesItem;
