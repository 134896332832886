import React from 'react';
import CreatorsSection from '../../../UX/CreatorsSection';
import Note from '../../../UX/Note';

function Installation() {
  return (
    <CreatorsSection 
      title="How to install?"
      href="how-to-install"
    >  
      <ul className="creatorsList">
        <li>
          First, <a href="https://connect.danim.com/register" target="_blank" rel="noreferrer">join the beta</a> by creating a Danim Creator account. Don't forget to click on the email we will send you to validate your account.
        </li>
        <li>
          By clicking on the activation link we've sent to you, a beta license is created for your account, and you are redirected to the <a href="https://app.danim.com/creators" target="_blank" rel="noreferrer">download page</a>.
        </li>
        <li>
          Download and install it (see windows and macOS issues). Then, launch Danim Creator.<br />For its first launch, it will ask for elevated permissions to finalize its installaton. Danim Creator needs to install an AE extension to communicate with After Effects, that's the same process than a manual installation with tools like ZXP Installer. Once the installation is completed, please restart After Effects if it was running. If an error message appears, please read the <a href="#troubleshooting">troubleshooting section</a>.
        </li>
        <li>
          Next time an update is available, Danim Creator will ask you if you want to update it automatically.
        </li>
      </ul>
      <Note status="info" title="Windows issues">
        On Windows, a blue "Smart Screen" can be shown before the installation. This process is safe, don't worry, just click on "Run anyway". We're working on a fix for the official release on these OS warnings, sorry for that.
      </Note>
      {/* TODO: Remove <Note status="info" title="macOS issues">
        On macOS, you have to right-click on the installer and click on "Open". If you double-click on it, you will not have the option to authorize the installation. This will be fixed in the forth coming weeks. Please <a href="https://www.howtogeek.com/205393/gatekeeper-101-why-your-mac-only-allows-apple-approved-software-by-default/" target="_blank" rel="noreferrer">read this</a> if you encounter some issues with Gatekeeper.
      </Note> */}
    </CreatorsSection>
  );
}

export default Installation;
