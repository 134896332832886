import React, { useState } from 'react';
import { Tabs, Tab, Stepper, Step, StepLabel, StepContent, Typography, Link, Grid, Divider, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { find } from 'lodash-es';
import ApiSection from '../../../../UX/ApiSection';
import CodeBlock from '../../../../UX/CodeBlock';
import codeSections from '../../../../../code/codeSections';
import ShortCode from '../../../../UX/ShortCode';
import Note from '../../../../UX/Note';
import graphQlPresets from '../../../../../utilities/graphQlPresets';

const useStyles = makeStyles((theme) => ({
  light: {
    backgroundColor: theme.palette.background.default,
  },
}));

const strictCheckRules = [
  'max_length',
  'min_numeric_value',
  'max_numeric_value',
  'is_int',
  'max_paragraph_line_length',
  'max_paragraph_lines',
  'values',
];

const textTypes = [
  'text',
  'number',
  'paragraph',
  'select',
  'boolean',
];

const fileTypes = [
  'image',
  'video',
  'image_video',
];

function GuidesCreateVideo() {
  const classes = useStyles();
  const [dataMode, setDataMode] = useState(0);
  const handleDataModeChange = (event, newValue) => {
    setDataMode(newValue);
  };

  const openGetTemplateSpecsByIdInGraphiQl = () => {
    if (window.goToGraphiQl) window.goToGraphiQl(codeSections.apiGetTemplateSpecsById, { id: '' }, false);
  };

  const openGetVideoByIdInGraphiQl = () => {
    const preset = find(graphQlPresets, { id: 'GetVideoDetailsById' });
    if (window.goToGraphiQl) window.goToGraphiQl(preset.query, preset.variables, false);
  };

  return (
    <ApiSection 
      title="Read a template and create a video"
      href="read-a-template-and-create-a-video"
      isSubSection
      description="Learn how to create a video"
      lightTitle
      hideDivider
    >
      <Stepper orientation="vertical" className="createVideoGuideSection">
        <Step active>
          <StepLabel className="stepLabel">Read and understand details about a template</StepLabel>
          <StepContent>
            <Typography variant="body2">
              Each template has a different structure. You need to know which data is needed by Danim for each template, which fields are required or not, the ids of these fields, etc.
              <br />You can find these informations by two different ways:
            </Typography>
            <Typography variant="subtitle1" className="colorSecondary w-bold mt-2">Easy way: with danim.com</Typography>
            <Typography variant="body2">
              You can find out how to write your video creation request on each generation page by clicking on the "code" icon below the template thumbnail.
            </Typography>
            <Typography variant="subtitle1" className="colorSecondary w-bold mt-2">Hard way: with GraphQL</Typography>
            <Typography variant="body2" component="div">
              Get the id of your template, and use GraphQL to find the specs about it.
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <div className="autoSticky">
                    <CodeBlock 
                      codeKey="apiGetTemplateSpecsById" 
                      language="graphql" 
                      light
                      title={(
                        <React.Fragment>
                          Get template specs by id - <Link className="cursor" onClick={openGetTemplateSpecsByIdInGraphiQl}>open in GraphiQL</Link>
                        </React.Fragment>
                      )}
                      className="mt-1"
                    />
                    <Note status="warning" title="Warning" className="mt-2" light>
                      You have to parse <ShortCode light>specs</ShortCode> in GraphQL response because this attribute is stringified.
                    </Note>
                  </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <div className="autoSticky">
                    <CodeBlock 
                      codeKey="apiGetTemplateSpecsByIdSpecsExample" 
                      language="json" 
                      light
                      title="Specs example"
                      className="mt-1"
                    />
                  </div>
                </Grid>
              </Grid>
            </Typography>
            <Typography variant="subtitle1" className="colorSecondary w-bold mt-2">How to understand specs?</Typography>
            <Typography variant="body2" component="div">
              A template is an assembly of several compositions in which there are several inputs (texts and files). Each composition and each input has rules that define the expected data.
              <br />One of the most important rules for compositions, texts and files is <ShortCode light>required</ShortCode>. In some cases, you can omit an input during the creation of a video and it will not be visible in the video. In other cases, you can't and an error will be thrown.
              <br /><br />Here is a summary:
              <ul>
                <li>you didn’t provide any input (required or optional) of an optional composition: <span className="colorLight">the composition itself will not appear in the video</span></li>
                <li>you didn't provide the value of an optional input in a required (or optional) composition: <span className="colorLight">its value will then be empty</span></li>
                <li>you didn't provide the value of a required input in a required composition or in an optional composition from which you provided another input: <span className="colorWarning">an error will be thrown</span> (see <a href="#reference-post-write-video-create">errors</a>)</li>
              </ul>
              Other informations such as <ShortCode light>description</ShortCode>, <ShortCode light>type</ShortCode>, <ShortCode light>max_length</ShortCode>, <ShortCode light>min_numeric_value</ShortCode>, <ShortCode light>min_width</ShortCode> (etc.) are easy to understand.
              <br />Please note that these rules are informative in most of the cases. For example, if you don't respect the rule <ShortCode light>max_length</ShortCode> for a text input, no error is returned unless <ShortCode light>strict_check</ShortCode> is <ShortCode light>TRUE</ShortCode>. These are the rules affected by <ShortCode light>strict_check</ShortCode>:
              <ul>
                {
                  strictCheckRules.map((rule, index) => <li key={index}><ShortCode light>{rule}</ShortCode></li>)
                }
              </ul>
              There are two main families of inputs: <ShortCode light>texts</ShortCode> and <ShortCode light>files</ShortCode>. There are several types in each of them:
              <ul>
                <li>For texts: {textTypes.join(', ')}</li>
                <li>For files: {fileTypes.join(', ')}</li>
              </ul>
            </Typography>
          </StepContent>
        </Step>
        <Step active>
          <StepLabel className="stepLabel">Create a video based on this template</StepLabel>
          <StepContent>
            <Typography variant="subtitle1" className="colorSecondary w-bold mt-2">File sending</Typography>
            <Typography variant="body2">
              We'll use the template specs above as an example. The field called <ShortCode light>poster</ShortCode> is a file, so you have to choose between the several ways described in <a href="#file-sending">file sending section</a> to send it.
              <br />We highly advise you to send your file(s) separately with <a href="#reference-post-upload-a-file">/upload/me</a> and get the file id in response with <ShortCode light>data.file.id</ShortCode>.
              <br /><br />Images have rules related to their dimensions: <ShortCode light>min_width</ShortCode>, <ShortCode light>min_height</ShortCode>, <ShortCode light>max_width</ShortCode>, <ShortCode light>max_height</ShortCode>.
              <br />These rules are informative, so if your image doesn't match the required dimensions, we'll automatically crop and resize it: no error will be thrown. You can ask us to crop these images by sending an additional <ShortCode light>crop</ShortCode> object (see expert mode below).
              <br /><br />Videos have rules related to their duration: <ShortCode light>duration_strategy</ShortCode> ("fixed" or "variable"), <ShortCode light>duration</ShortCode>, <ShortCode light>min_duration</ShortCode>, <ShortCode light>max_duration</ShortCode>. If <ShortCode light>duration_strategy</ShortCode> is "fixed", video duration is defined by <ShortCode light>duration</ShortCode>. If <ShortCode light>duration_strategy</ShortCode> is "variable", video duration has to be defined between <ShortCode light>min_duration</ShortCode> and <ShortCode light>max_duration</ShortCode>.
              <br />As for images, these rules are informative, so if your video doesn't match the specified duration, we'll automatically cut it: no error will be thrown. You can ask us to cut these videos by sending an additional <ShortCode light>range</ShortCode> array (see expert mode below). 
            </Typography>
            <Typography variant="subtitle1" className="colorSecondary w-bold mt-2">Launch a new generation</Typography>
            <Typography variant="body2" component="div">
              Now that you get all the details needed to create a video, we can use the endpoint <a href="#reference-post-write-video-create">/write/video/create</a> to launch a new generation.
              <br /><br /><ShortCode light>template_data</ShortCode> can have 2 different layouts depending on the features you want to use. A minimalist and easy to use mode allows you to create a video without having to set the ratio and the theme. In this mode, you can't duplicate the compositions or define custom crop and cut values for images and videos. All you have to do is to send a list of entries you want to see in your video.
              <Tabs
                value={dataMode}
                onChange={handleDataModeChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="template_data layout switcher"
                className="mt-2 mb-2"
              >
                <Tab className={clsx('w-bolder ms-2', classes.light)} label="Minimalist" />
                <Tab className={clsx('w-bolder ms-2', classes.light)} label="Expert" />
              </Tabs>
              <div className="mb-2">
                {
                  dataMode === 0 ? (
                    <React.Fragment>
                      Here are different scenarios based on the specs above. To simplify, <ShortCode light>template_data</ShortCode> is the only displayed field, but other required fields have to be sent too:
                      <CodeBlock 
                        codeKey="apiCreateVideoGuideExample1" 
                        language="json" 
                        light
                        title={<React.Fragment>Video with all fields - <span className="colorLight">success</span></React.Fragment>}
                        className="mt-1 mb-2"
                      />
                      <Divider />
                      <CodeBlock 
                        codeKey="apiCreateVideoGuideExample2" 
                        language="json" 
                        light
                        title={<React.Fragment>Minimal video without all the optional fields - <span className="colorLight">success</span></React.Fragment>}
                        className="mt-2 mb-1"
                      />
                      <Typography variant="caption">
                        In this example, the composition <ShortCode light>01-intro</ShortCode> will not be displayed in the generated video because all these inputs are missing in the request. 
                        The image <ShortCode>poster</ShortCode> will not be displayed and no error will be thrown because it's not a required field.
                      </Typography>
                      <Divider className="mt-1" />
                      <CodeBlock 
                        codeKey="apiCreateVideoGuideExample3" 
                        language="json" 
                        light
                        title={<React.Fragment>Missing inputs - <span className="colorError">error</span></React.Fragment>}
                        className="mt-2 mb-1"
                      />
                      <Typography variant="caption">
                        Here, two required fields are missing: <ShortCode light>title</ShortCode> and <ShortCode light>score</ShortCode>. Error <ShortCode type="error" light>MissingTemplateDataInput</ShortCode> will be thrown.
                      </Typography>
                      <Divider className="mt-1" />
                      <CodeBlock 
                        codeKey="apiCreateVideoGuideExample4" 
                        language="json" 
                        light
                        title={<React.Fragment>Bad input value - <span className="colorError">error</span></React.Fragment>}
                        className="mt-2 mb-1"
                      />
                      <Typography variant="caption">
                        In this last example, two fields have wrong values when <ShortCode ligth>strict_check</ShortCode> is <ShortCode light>TRUE</ShortCode>. <ShortCode light>duration</ShortCode> is a float but it should be an integer, and <ShortCode light>score</ShortCode> is less than 0 while <ShortCode light>min_numeric_value</ShortCode> is <ShortCode light>0</ShortCode>. Error <ShortCode type="error" light>BadParameter</ShortCode> will be thrown.
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Typography variant="subtitle2" className="colorSecondary w-bold m-1">Info: Edit ratio and colors</Typography>
                      <Typography variant="body2" className="mb-2">
                        Some templates can have several aspect ratios which are listed in the <ShortCode light>info.ratios</ShortCode> array in the specs. You can choose which ratio you want to use thanks to <ShortCode light>ratio_index</ShortCode>. Send <ShortCode light>ratio_index: 1</ShortCode> for the template with the specs above (ratios <ShortCode light>[1, 1.78, 0.56]</ShortCode>) to create a landscape video (ratio 1.78).
                        <br /><br />
                        Each template has a different color scheme described in <ShortCode light>info.theme.layout</ShortCode> and multiple variants (<ShortCode light>info.theme.variants</ShortCode>) that use all the colors available in the template. Variants in the specs can help you creating your own theme. Each color has a unique color id ("main-color", "secondary-color" and "text-color" in our example). Simply send hex color code of each color in object <ShortCode light>info.theme.custom_variant</ShortCode> to create your own theme.
                      </Typography>
                      <Typography variant="subtitle2" className="colorSecondary w-bold m-1">Compositions: Get more control on your content</Typography>
                      <Typography variant="body2" className="mb-2">
                        In expert mode, texts and files have to be sent inside their composition. This allows you to duplicate and reorder dynamic compositions. Dynamic compositions can be duplicated inside their dynamic zone. In the specs above, we can see that composition "03-casting" has the rule <ShortCode light>dynamic_zone_start</ShortCode> and composition "04-quote" has the rule <ShortCode light>dynamic_zone_end</ShortCode>, which means that a dynamic zone exists between these two compositions. These two compositions can be used several times within this dynamic zone and can never be inserted outside this zone. They must be used within the limits defined <ShortCode light>min_dynamic_zone_quantity</ShortCode> and <ShortCode light>max_dynamic_zone_quantity</ShortCode>. Each composition has to be sent through the <ShortCode light>compositions</ShortCode> array with its id, texts and files attributes. If you want to exclude some optional compositions, just don't send them.
                      </Typography>
                      <Typography variant="subtitle2" className="colorSecondary w-bold m-1">Texts and files content</Typography>
                      <Typography variant="body2" className="mb-2">
                        Each input has to be sent with its id and its content. <ShortCode light>content</ShortCode> is a string, an integer or a boolean for <ShortCode light>texts</ShortCode> and a file id, an url or a binary for files. In addition to  <ShortCode light>id</ShortCode> and  <ShortCode light>content</ShortCode>, you can use the <ShortCode light>rules</ShortCode> object to edit advanced attributes.
                        <br /><br /><ShortCode light>rules.crop</ShortCode> can be used to crop your images. Send <ShortCode light>{'{ x, y, width, height }'}</ShortCode> object to extract a specific area of your image from the point located at the coordinates x, y. Each value is expressed in pixels.
                        <br /><ShortCode light>rules.range</ShortCode> can be used to cut your videos. Send <ShortCode light>[5, 12.5]</ShortCode> to cut your video from 5 to 12.5 seconds.
                        <br /><ShortCode light>rules.muted</ShortCode> boolean can be used to mute your video. This value is ignored if <ShortCode light>force_muted</ShortCode> is set on true in the specs of the input.
                        <Note status="info" title="What if I don't meet the specs?" className="mt-2" light>
                          We really encourage you to use values that match the specs. For example, do not send a <ShortCode light>range</ShortCode> that results in a longer duration than defined by the specs. If you make a mistake and send incompatible rules, Danim will change your rules to automatically match the specs, and your video will be created.
                        </Note>
                      </Typography>
                      <Typography variant="body2" className="mb-2">
                        Here is an example of <ShortCode light>template_data</ShortCode> that uses all the concepts discussed above:
                        <CodeBlock 
                          codeKey="apiCreateVideoGuideExpertExample1" 
                          language="json" 
                          light
                          title="Expert mode example"
                          className="mt-1 mb-2"
                        />
                      </Typography>
                    </React.Fragment>
                  )
                }
              </div>
            </Typography>
            <Note status="info" title='null, false, "" or undefined?' className="mt-2" light>
              The easiest way to tell Danim to ignore an input is to not send this input in <ShortCode>template_data</ShortCode>. You can also send <ShortCode>null</ShortCode>, but if you send an empty string <ShortCode>""</ShortCode> or <ShortCode>false</ShortCode>, the value of the input will be evaluated.
            </Note>
          </StepContent>
        </Step>
        <Step active>
          <StepLabel className="stepLabel">Get back video urls</StepLabel>
          <StepContent>
            <Typography variant="body2" component="div">
              If everything is fine with your <ShortCode light>template_data</ShortCode>, you'll receive a similar response (with different ids):
              <Grid container spacing={2} className="mt-1 mb-1">
                <Grid item xs={12} lg={6}>
                  <CodeBlock 
                    codeKey="apiCreateVideoGuideFullRequest" 
                    language="json" 
                    light
                    title="/write/video/create Request"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CodeBlock 
                    codeKey="apiCreateVideoGuideResponse" 
                    language="json" 
                    light
                    title={<React.Fragment>Response - <span className="colorLight">success</span></React.Fragment>}
                  />
                </Grid>
              </Grid>
            </Typography>
            <Typography variant="subtitle1" className="colorSecondary w-bold mt-2">Be notified with a webhook</Typography>
            <Typography variant="body2" component="div">
              Once your video has been created, we'll notify you with a <ShortCode light>POST</ShortCode> request (<ShortCode light>application/x-www-form-urlencoded</ShortCode>) at your <ShortCode>callback_url</ShortCode>.
              <br />These attributes are sent: <ShortCode light>id</ShortCode>, <ShortCode light>raw_url</ShortCode> (full resolution video), <ShortCode light>raw_quicklook_url</ShortCode> (GIF preview), <ShortCode light>raw_thumbnail_url</ShortCode> (main thumbnail), and <ShortCode light>player_url</ShortCode>.
              <br /><br />This is a common use case of <ShortCode>callback_url</ShortCode>:
              <ul>
                <li>Save <ShortCode light>data.id</ShortCode> from <a href="#reference-post-write-video-create">/write/video/create</a> response in your database</li>
                <li>When a request is received at your <ShortCode light>callback_url</ShortCode>, find the related video in your database thanks to the <ShortCode light>id</ShortCode> in <ShortCode light>POST</ShortCode> parameters</li>
                <li>You're now sure that this video has been created. You can save data sent by the webhook in your database, or get additional informations with <Link className="cursor" onClick={openGetVideoByIdInGraphiQl}>another GraphQL request</Link></li>
              </ul>
            </Typography>
            <Note status="info" title="Quick tip" light>
              During your tests with our API, we highly suggest that you use <a href="https://webhook.site/" target="_blank" rel="noreferrer">webhook.site</a> to try the <ShortCode light>callback_url</ShortCode> attribute.
            </Note>
          </StepContent>
        </Step>
      </Stepper>
    </ApiSection>
  );
}

export default GuidesCreateVideo;
