import { Typography } from '@material-ui/core';
import React from 'react';

function ChangelogItem(props) {
  const { version, children } = props;
  return (
    <div className="mb-2">
      <Typography variant="overline" display="block" className="colorLight">{version}</Typography>
      <Typography variant="caption" display="block">{children}</Typography>
    </div>
  );
}

export default ChangelogItem;
