const fs = require('fs');

// Content-Type
const apiContentTypeUrlEncoded = fs.readFileSync(`${__dirname}/api/content-type/apiContentTypeUrlEncoded.txt`, 'utf-8');
const apiContentTypeJsonEncoded = fs.readFileSync(`${__dirname}/api/content-type/apiContentTypeJsonEncoded.txt`, 'utf-8');

// Evaluating Responses
const apiEvaluatingResponsesSuccessExample = fs.readFileSync(`${__dirname}/api/evaluating-responses/apiEvaluatingResponsesSuccessExample.txt`, 'utf-8');
const apiEvaluatingResponsesErrorExample = fs.readFileSync(`${__dirname}/api/evaluating-responses/apiEvaluatingResponsesErrorExample.txt`, 'utf-8');

// File sending
const apiFileSendingBinaryExample = fs.readFileSync(`${__dirname}/api/file-sending/apiFileSendingBinaryExample.txt`, 'utf-8');
const apiFileSendingReadableCloudUrlExample = fs.readFileSync(`${__dirname}/api/file-sending/apiFileSendingReadableCloudUrlExample.txt`, 'utf-8');

// Authentication
const apiAuthenticationBase64Example = fs.readFileSync(`${__dirname}/api/authentication/apiAuthenticationBase64Example.txt`, 'utf-8');
const apiAuthenticationHeadersExample = fs.readFileSync(`${__dirname}/api/authentication/apiAuthenticationHeadersExample.txt`, 'utf-8');
const apiAuthenticationQueryParametersExample = fs.readFileSync(`${__dirname}/api/authentication/apiAuthenticationQueryParametersExample.txt`, 'utf-8');

// GraphQL
const apiGetTemplateSpecsById = fs.readFileSync(`${__dirname}/api/graphql/apiGetTemplateSpecsById.txt`, 'utf-8');
const apiGetTemplateSpecsByIdSpecsExample = fs.readFileSync(`${__dirname}/api/graphql/apiGetTemplateSpecsByIdSpecsExample.txt`, 'utf-8');

// Video guide
const apiCreateVideoGuideFullRequest = fs.readFileSync(`${__dirname}/api/create-video-guide/apiCreateVideoGuideFullRequest.txt`, 'utf-8');
const apiCreateVideoGuideExample1 = fs.readFileSync(`${__dirname}/api/create-video-guide/apiCreateVideoGuideExample1.txt`, 'utf-8');
const apiCreateVideoGuideExample2 = fs.readFileSync(`${__dirname}/api/create-video-guide/apiCreateVideoGuideExample2.txt`, 'utf-8');
const apiCreateVideoGuideExample3 = fs.readFileSync(`${__dirname}/api/create-video-guide/apiCreateVideoGuideExample3.txt`, 'utf-8');
const apiCreateVideoGuideExample4 = fs.readFileSync(`${__dirname}/api/create-video-guide/apiCreateVideoGuideExample4.txt`, 'utf-8');
const apiCreateVideoGuideExpertExample1 = fs.readFileSync(`${__dirname}/api/create-video-guide/apiCreateVideoGuideExpertExample1.txt`, 'utf-8');
const apiCreateVideoGuideResponse = fs.readFileSync(`${__dirname}/api/create-video-guide/apiCreateVideoGuideResponse.txt`, 'utf-8');

export default {
  // Content-Type
  apiContentTypeUrlEncoded,
  apiContentTypeJsonEncoded,
  // Evaluating Responses
  apiEvaluatingResponsesSuccessExample,
  apiEvaluatingResponsesErrorExample,
  // File sending
  apiFileSendingBinaryExample,
  apiFileSendingReadableCloudUrlExample,
  // Authentication
  apiAuthenticationBase64Example,
  apiAuthenticationHeadersExample,
  apiAuthenticationQueryParametersExample,
  // GraphQL
  apiGetTemplateSpecsById,
  apiGetTemplateSpecsByIdSpecsExample,
  // Guides
  apiCreateVideoGuideFullRequest,
  apiCreateVideoGuideExample1,
  apiCreateVideoGuideExample2,
  apiCreateVideoGuideExample3,
  apiCreateVideoGuideExample4,
  apiCreateVideoGuideExpertExample1,
  apiCreateVideoGuideResponse,
};
