const graphQlPresets = [
  {
    id: 'GetVideosByState',
    label: 'Get videos by state',
    tooltip: 'Get a list of videos by state',
    query: `query GetVideosByState($quantity: Int = 5, $state: VideoState = DONE) {
        videos(
            first: $quantity 
            state: $state
        ) {
          edges {
            node {
              id
              name
              shortId
            }
          }
        }
      }`,
    variables: {
      quantity: 5,
      state: 'DONE',
    },
  },
  {
    id: 'GetVideoDetailsById',
    label: 'Get video details by id',
    tooltip: 'Get details about a video with its id',
    query: `query GetVideoDetailsById($id: String) {
      video(id: $id) {
        id
        shortId
        name
        state
        description
        thumbnail {
          src
        }
        file {
          src
        }
        progress {
          duration
          percentage
        }
      }
    }`,
    variables: {
      id: '',
    },
  },
  {
    id: 'GetTemplatesByWorspace',
    label: 'Get templates by workspace id',
    tooltip: 'Get a list of templates in a specific worspace (or not, if $worspaceId is null)',
    query: `query GetTemplatesByWorspace($inWorkspace: BoolableString, $public: Boolean) {
      templates(inWorkspace: $inWorkspace, public: $public) {
        edges {
          node {
            id
            name
            shortId
          }
        }
      }
    }`,
    variables: {
      inWorkspace: false,
      public: false,
    },
  },
  {
    id: 'GetTemplateById',
    label: 'Get template by id',
    tooltip: 'Get details about a template with its id',
    query: `query GetTemplateById($id: String) {
      template(id: $id) {
        name
        shortId
        inputs
        specs
        thumbnail {
          src
        }
        preview {
          src
        }
      }
    }`,
    variables: {
      id: '',
    },
  },
  {
    id: 'GetWorspaces',
    label: 'Get worspaces',
    tooltip: 'Get details about a workspace',
    query: `query GetWorspaces {
      workspaces {
        edges {
          node {
            id
            name
          }
        }
      }
    }`,
    variables: {},
  },
];

export default graphQlPresets;
