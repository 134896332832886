import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { debounce } from 'lodash-es';
import { Button, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ApiSection from '../../../../UX/ApiSection';
import CustomGraphiQL from '../../CustomGraphiQL';
import graphQlPresets from '../../../../../utilities/graphQlPresets';
import 'graphiql/graphiql.min.css';
// TODO: Change schema path
import schema from '../../../../../../assets/json/dnm-gql-schema.json';

const DEFAULT_APP_ID = '';
const DEFAULT_APP_SECRET = '';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.background.dark,
  },
}));

function ApiReferenceReadRoute() {
  const classes = useStyles();
  const [graphiQl, setGraphiQl] = useState(null);
  const [graphiQlState, setGraphiQlState] = useState({
    appId: DEFAULT_APP_ID,
    appSecret: DEFAULT_APP_SECRET,
  });

  useEffect(() => {
    window.goToGraphiQl = (query, variables, run = false) => {
      if (query || variables) editGraphiQl(query, variables, run);
      graphiQl.graphiqlContainer.scrollIntoView();
    };
  }, [graphiQl]);

  useEffect(() => {
    if (graphiQl) {
      const { appId, appSecret } = graphiQlState;
      if (appId && appSecret) runGraphiQlQuery();
    }
  }, [graphiQlState, graphiQl]);

  const editGraphiQl = (query, variables, run = false) => {
    // if (query) graphiQl.queryEditorComponent.editor.setValue(query);
    if (query) graphiQl.handleEditQuery(query);
    if (variables) {
      graphiQl.handleEditVariables(JSON.stringify(variables));
      graphiQl.handleOpenVariableEditorTab();
    }
    setTimeout(() => {
      graphiQl.handlePrettifyQuery();
      if (run === true) runGraphiQlQuery();
    }, 100);
  };

  const runGraphiQlQuery = () => {
    graphiQl.handleRunQuery();
  };

  const handleLoadQueryClick = preset => {
    const { variables, query } = preset;
    editGraphiQl(query, variables);
    setTimeout(runGraphiQlQuery, 100);
  };

  const _handleAppIdChange = useCallback(debounce((value) => {
    setGraphiQlState(state => ({ ...state, appId: value })); 
  }, 1000), []);
  const _handleAppSecretChange = useCallback(debounce((value) => {
    setGraphiQlState(state => ({ ...state, appSecret: value })); 
  }, 1000), []);

  const handleAppIdChange = event => _handleAppIdChange(event.target.value);
  const handleAppSecretChange = event => _handleAppSecretChange(event.target.value);

  const { appId, appSecret } = graphiQlState;
  return (
    <ApiSection 
      title="/read"
      href="reference-read"
      isSubSection
      description={(
        <React.Fragment>
          All our read API is explorable with GraphiQL. You can navigate between several examples of queries by clicking on the buttons below the explorer. The GraphQL documentation adapts to your scopes, so don't be surprised if it changes in the Explorer after you fill in your App ID and App Secret.
        </React.Fragment>
        )}
      lightTitle
      hideDivider
    >
      <React.Fragment>
        {
          !appId || !appSecret ? (
            <Typography diplay="block" variant="body2" className="mt-1 colorWarning">
              Register your app credentials to start exploring our API.
            </Typography>
          ) : null
        }
        <div className="mb-2">
          <TextField
            className="mr-2"
            label="App ID"
            onChange={handleAppIdChange}
            margin="dense"
          />
          <TextField
            className="mr-2"
            label="App Secret"
            onChange={handleAppSecretChange}
            type="password"
            margin="dense"
          />
        </div>
        <div className="graphiQlRoot">
          <CustomGraphiQL 
            onInit={setGraphiQl}
            appId={appId}
            appSecret={appSecret}
            queryOnMount={graphQlPresets[0].query}
            variablesOnMount={graphQlPresets[0].variables}
            schemaOnMount={schema}
          />
        </div>
        <div className="mt-2">
          <Typography variant="overline" className="colorLight mr-2">
            Examples:
          </Typography>
          {
            graphQlPresets.map(preset => (
              <Tooltip key={preset.id} classes={{ tooltip: 'tooltip' }} title={preset.tooltip} placement="top">
                <Button className={clsx(classes.button, 'mr-1', 'mb-1')} color="secondary" onClick={() => handleLoadQueryClick(preset)}>
                  { preset.label }
                </Button>
              </Tooltip>
            ))
          }
        </div>
      </React.Fragment>
    </ApiSection>
  );
}

export default ApiReferenceReadRoute;
