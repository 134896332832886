import React from 'react';
import ApiSection from '../../../UX/ApiSection';
import GuidesCreateVideo from './Guides/GuidesCreateVideo';

function Guides() {
  return (
    <ApiSection 
      title="Guides"
      description="You can find here multiple guides which will help you step by step in your use of Danim API"
      hideDivider
    >
      <GuidesCreateVideo />
    </ApiSection>
  );
}

export default Guides;
