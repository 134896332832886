import React, { useMemo, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { 
  AppBar, 
  Toolbar, 
  Icon,
  Drawer, 
  IconButton, 
  Divider, 
  useScrollTrigger,
  Slide,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { throttle } from 'lodash-es';
import logo from '../../../../assets/images/logo-danim-creator.png';
import Nav from '../../UX/Nav';
import CreatorsSectionsContext from '../../../utilities/contexts/CreatorsSectionsContext';
import Introduction from './Sections/Introduction';
import Prerequisite from './Sections/Prerequisite';
import Installation from './Sections/Installation';
import Troubleshooting from './Sections/Troubleshooting';
import Guides from './Sections/Guides';
import Changelog from './Sections/Changelog';

import useToggle from '../../../utilities/hooks/useToggle';
import creatorsNavList from '../../../utilities/creatorsNavList';

const getAllItemInNav = (root, items = [], deps = []) => {
  const addItem = (item, _deps) => {
    items.push({ 
      ...item, 
      ...(_deps.length > 0 && { deps: _deps }),
    });
  };
  root.forEach(item => {
    const { list, id } = item;
    if (!list) addItem(item, deps);
    else {
      const _deps = [...deps];
      _deps.push(id);
      getAllItemInNav(list, items, _deps);
    }
  });
  return items;
};

const navProjection = getAllItemInNav(creatorsNavList);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.default,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 'auto',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    color: 'white',
    boxShadow: '5px 0 15px 0px rgba(0,0,0,0.2)',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  drawerLogo: {
    height: '24px',
  },
  content: {
    width: '100%',
    flexGrow: 1,
    padding: '20px 20px 150px 20px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    color: theme.palette.text.primary,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  activeLink: {
    color: theme.palette.primary.light,
  },
}));

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function scrollToUrlAnchor() {
  const parts = location.href.split('#');
  if (parts.length > 1) {
    const anchor = parts[parts.length - 1];
    if (anchor) {
      setTimeout(() => {
        const el = document.getElementById(anchor);
        if (el) el.scrollIntoView(true);
      }, 100);
    }
  }
}

function Creators() {
  const classes = useStyles();
  const [activeSectionId, setActiveSectionId] = useState('introduction');
  const [open, , toggleOpen] = useToggle(window.innerWidth > 800);
    
  const _updateActiveSectionId = id => {
    if (history.pushState) history.pushState(null, null, `#${id}`);
    setActiveSectionId(id);
  };
  const updateActiveSectionId = useCallback(throttle(_updateActiveSectionId, 200), []);

  function setLastScrollDirection() {
    const direction = window.oldScroll > window.scrollY ? 'up' : 'down';
    window.lastScrollDirection = direction;
    window.oldScroll = window.scrollY;
    return direction;
  }

  useEffect(() => {
    scrollToUrlAnchor();
    window.addEventListener('scroll', setLastScrollDirection);
    return () => {
      window.removeEventListener('scroll', setLastScrollDirection);
    };
  }, []);

  const creatorsSectionsContextValue = useMemo(() => ({
    onWayPointEnter: href => {
      if (href) {
        if (window.scrollY <= 150) updateActiveSectionId(creatorsNavList[0].id);
        else updateActiveSectionId(href);
      }
    },
    onWayPointLeave: href => {
      if (href === activeSectionId) {
        let newActiveSectionId = null;
        for (let i = 0; i < navProjection.length; i++) {
          const { id } = navProjection[i];
          if (id === href) {
            const newIndex = window.lastScrollDirection === 'up' ? i - 1 : i + 1;
            if (newIndex >= 0 && newIndex < navProjection.length) newActiveSectionId = navProjection[newIndex].id;
            break;
          }
        }
        if (newActiveSectionId !== null) updateActiveSectionId(newActiveSectionId);
      }
    },
  }), []);
  
  const onLinkClick = href => setTimeout(() => {
    creatorsSectionsContextValue.onWayPointEnter(href);
  }, 200);

  return (
    <React.Fragment>
      <HideOnScroll>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={toggleOpen}
              edge="start"
              className={classes.menuButton}
            >
              <Icon>{open ? 'chevron_left' : 'menu'}</Icon>
            </IconButton>
            <a href="/api" className="colorWhite mr-2">
              Go to API docs
            </a>
            <a href="https://danim.com/creators" className="colorWhite">
              Back to danim.com
            </a>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <a className={clsx(classes.drawerHeader, 'cursor', 'colorSecondary')} href="#introduction">
          <img className={clsx(classes.drawerLogo, 'ml-1 mr-1')} src={logo} alt="Logo Danim" />
          <span className="w-bolder">Danim Creator Docs</span>
        </a>
        <Divider />
        <Nav list={creatorsNavList} projection={navProjection} activeId={activeSectionId} onLinkClick={onLinkClick} />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <CreatorsSectionsContext.Provider value={creatorsSectionsContextValue}>
          { useMemo(() => (
            <React.Fragment>
              <Introduction />
              <Prerequisite />
              <Installation />
              <Troubleshooting />
              <Guides />
              <Changelog />
            </React.Fragment>
          ), []) }
        </CreatorsSectionsContext.Provider>
      </main>
    </React.Fragment>
  );
}

export default Creators;
