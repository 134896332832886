import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import CreatorsSection from '../../../UX/CreatorsSection';
import Note from '../../../UX/Note';

function Prerequisite() {
  return (
    <CreatorsSection 
      title="Prerequisites"
      href="prerequisite"
      description={(
        <React.Fragment>
          Some softwares and tools are needed or highly recommended to
          use all the features of Danim Creator.
        </React.Fragment>
          )}
    >  
      <div className="mt-2">
        <div className="ps-1 mt-1">
          <Typography variant="overline">After Effects CC</Typography>
          <Typography display="block" variant="caption">
            <a href="https://www.adobe.com/products/aftereffects.html" target="_blank" rel="noreferrer">
              After Effects CC
            </a> is required to use Danim Creator. We officially support
            versions still distributed by Adobe through Creative
            Cloud. There are known issues on After Effects 17.5, please update to 17.5.1 or above if you are using this version.
          </Typography>
        </div>
        <Divider className="light-divider mb-1 mt-1" variant="inset" />
        <div className="ps-1 mt-1" id="ffmpeg-install">
          <Typography variant="overline" className="color-secondary">
            FFmpeg - Optional
          </Typography>
          <div>
            <Typography display="block" variant="caption">
              FFmpeg is needed for transcoding features. To enable these features, go to Danim Creator settings to install FFmpeg.
              <br /><a href="https://ffmpeg.org" target="_blank" rel="noreferrer">
                FFmpeg
              </a> is licensed under the{' '}
              <a href="https://raw.githubusercontent.com/devdanim/dnm-ffmpeg-builds/main/FFMPEG-LICENSE.txt" target="_blank" rel="noreferrer">
                GPLv3
              </a>{' '}
              and its source code can be downloaded{' '}
              <a href="https://github.com/FFmpeg/FFmpeg/archive/n4.3.1.zip" target="_blank" rel="noreferrer">
                here
              </a>.
              <br />
            </Typography>
          </div>
        </div>
        <Divider className="light-divider mb-1 mt-1" variant="inset" />
        <div className="ps-1 mt-1">
          <Typography variant="overline" className="color-secondary">
            Danim Box - Optional (<strong>highly recommended!</strong>)
          </Typography>
          <Typography display="block" variant="caption">
            We strongly encourage you to use{' '}
            <a href="https://danim.com/creators/danim_box" target="_blank" rel="noreferrer">
              Danim Box
            </a>{' '}
            to design your templates. Danim Box is a free script for After Effects allowing
            you to adapt your texts and media files inside boxes.
            That way, whatever the length of your text, or the
            dimensions of your image, the size of your target will
            adapt to your box. This is very useful to create
            multiple videos based on a single template.
            <br /><br />Using Danim Box will let Danim Creator detect automatically all your variable inputs.
          </Typography>
        </div>
        <Note status="warning" title="Warning" className="mt-2">
          Apple M1 processor is not supported yet. We will send an email to all macOS users as soon as it has been fixed: <a target="_blank" rel="noreferrer" href="https://helpx.adobe.com/download-install/kb/apple-silicon-m1-chip.html#CanIinstallpluginsonAppleM1computers">see Adobe documentation</a> to more details.
        </Note>
      </div>
    </CreatorsSection>
  );
}

export default Prerequisite;
