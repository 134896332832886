import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import GraphiQL from 'graphiql';
import GraphiQLExplorer from 'graphiql-explorer';
import fetch from 'isomorphic-fetch';
import { buildClientSchema } from 'graphql';
import useToggle from '../../../utilities/hooks/useToggle';

function CustomGraphiQL(props) {
  const graphiQlRef = useRef(null);
  const rootRef = useRef(null);
  const { appId, appSecret, onInit, queryOnMount, variablesOnMount, schemaOnMount } = props;
  const _schemaOnMount = useMemo(() => (schemaOnMount ? buildClientSchema(schemaOnMount) : undefined), []);

  const [graphiQlState, setGraphiQlState] = useState({ query: queryOnMount, variables: variablesOnMount, schema: _schemaOnMount, renderKey: 0 });
  const [explorerIsOpen, , toggleExplorerIsOpen] = useToggle(true);

  const { query, schema, renderKey, variables } = graphiQlState;

  useEffect(() => {
    if (onInit) onInit(graphiQlRef.current);
    handlePrettifyClick();
  }, [renderKey]);

  useEffect(() => {
    if (appId && appSecret) setGraphiQlState(state => ({ ...state, schema: undefined, renderKey: renderKey + 1 }));
    else if (_schemaOnMount) setGraphiQlState(state => ({ ...state, schema: _schemaOnMount }));
  }, [appId, appSecret]);

  const graphQLFetcher = useCallback(
    (graphQLParams) => {
      console.log('GraphiQL fetch: ', graphQLParams.operationName);
      const promise = fetch('https://api.danim.com/read', {
        method: 'post',
        headers: { 
          'Content-Type': 'application/json',
          ...appId && { 'X-App-Id': appId },
          ...appSecret && { 'X-App-Secret': appSecret },
        },
        body: JSON.stringify(graphQLParams), 
      }).then(response => response.json());
      if (graphQLParams.operationName === 'IntrospectionQuery') {
        promise.then(result => {
          const { success, data } = result;
          if (success && data) setGraphiQlState(state => ({ ...state, schema: buildClientSchema(data) }));
        });
      }
      return promise;
    },    
    [appId, appSecret],
  );

  const handlePrettifyClick = () => {
    graphiQlRef.current.handlePrettifyQuery();
  };

  const handleToggleHistory = () => {
    graphiQlRef.current.handleToggleHistory();
  };

  const handleEditQuery = (newQuery) => {
    setGraphiQlState(state => ({ ...state, query: newQuery }));
  };

  const handleEditVariables = (newVariables) => {
    try {
      const _variables = JSON.parse(newVariables);
      setGraphiQlState(state => ({ ...state, variables: _variables }));
    } catch (e) {}
  };

  const handleForceEditQuery = (newQuery) => {
    graphiQlRef.current.handleEditQuery(newQuery);
  };

  const handleRunOperation = (operationName) => {
    graphiQlRef.current.handleRunQuery(operationName);
  };

  const toggleFullScreen = () => {
    const { current } = rootRef;
    if (document.fullscreenElement) { 
      document.exitFullscreen(); 
    } else { 
      current.requestFullscreen(); 
    } 
  };

  return (
    <div className="graphiql-container" ref={rootRef}>
      <GraphiQLExplorer
        schema={schema}
        query={query}
        onEdit={handleForceEditQuery}
        onRunOperation={handleRunOperation}
        explorerIsOpen={explorerIsOpen}
        onToggleExplorer={toggleExplorerIsOpen}
      />
      {
        useMemo(() => (
          <GraphiQL 
            defaultSecondaryEditorOpen 
            key={renderKey}
            fetcher={graphQLFetcher} 
            query={query} 
            schema={schema}
            onEditQuery={handleEditQuery}
            onEditVariables={handleEditVariables}
            variables={JSON.stringify(variables)}
            docExplorerOpen={false}
            ref={graphiQlRef}
          >
            <GraphiQL.Toolbar>
              <GraphiQL.Button
                onClick={handlePrettifyClick}
                label="Prettify"
                title="Prettify Query (Shift-Ctrl-P)"
              />
              <GraphiQL.Button
                onClick={handleToggleHistory}
                label="History"
                title="Show History"
              />
              <GraphiQL.Button
                onClick={toggleExplorerIsOpen}
                label="Explorer"
                title="Toggle Explorer"
              />
              <GraphiQL.Button
                onClick={toggleFullScreen}
                label="Fullscreen"
                title="Toggle Fullscreen"
              />
            </GraphiQL.Toolbar>
          </GraphiQL>
        ), [graphQLFetcher, explorerIsOpen, renderKey, schema])
      }
    </div>
  );
}

CustomGraphiQL.CustomGraphiQL = {
  queryOnMount: '',
  variablesOnMount: {},
};

export default CustomGraphiQL;
