import React from 'react';
import { Typography } from '@material-ui/core';
import ApiSection from '../../../../UX/ApiSection';
import Note from '../../../../UX/Note';
import CodeBlock from '../../../../UX/CodeBlock';
import ShortCode from '../../../../UX/ShortCode';

function EvaluatingResponses() {
  return (
    <ApiSection 
      title="Evaluating responses"
      href="evaluating-responses"
      description={(
        <React.Fragment>
          Each web API response contains a JSON object, which will always contain a top-level boolean property <ShortCode type="success">success</ShortCode>, 
          indicating success or failure. The response code will always be <ShortCode type="success">200</ShortCode>.
        </React.Fragment>
      )}
    >
      <Note status="warning" title="Warning">
        Do not forget to include treatment in your code for <ShortCode type="warning" light>40x</ShortCode> and <ShortCode type="warning" light>50x</ShortCode> responses, which like in all online protagonists could potentially occur.
      </Note>
      <Typography variant="body2" className="mt-2">
        For success results, the <ShortCode>data</ShortCode> object property will contain useful pieces of information, 
        such as the ID of a newly created entity, for example. 
        Its internal fields are completely dependant on the endpoint.
        It is <strong>highly recommended</strong> that you log the <ShortCode>meta.warnings</ShortCode> on your side, to be alerted in real-time of any potential issues and deprecations.
      </Typography>
      <CodeBlock codeKey="apiEvaluatingResponsesSuccessExample" language="json" className="mt-1 mb-1" />
      <Typography variant="body2" className="mt-2">
        For failure results, the <ShortCode type="warning">message</ShortCode> property will contain a detailed and verbose description of what had failed, 
        and why, and how. There also will be a short machine-readable error code, found under <ShortCode type="warning">meta.type</ShortCode>, 
        to get a more generic and clean way of dealing with errors 
        (since this will be much more static in the course of time).
      </Typography>
      <CodeBlock codeKey="apiEvaluatingResponsesErrorExample" language="json" className="mt-1 mb-1" />
    </ApiSection>
  );
}

export default EvaluatingResponses;
