import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreatorsSection from '../../../UX/CreatorsSection';
import YoutubePlayer from './Guides/YoutubePlayer';
import CreateTemplate from './Guides/CreateTemplate';
import UploadTemplate from './Guides/UploadTemplate';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.background.dark,
  },
}));

const frPlaylist = [
  {
    title: 'Présentation',
    id: '8EdjTInXJPk',
    chapters: {
      '00:00': 'Présentation',
      '02:32': 'Création d\'une vidéo',
      '08:53': 'Personnalisation du template',
      '11:15': 'Thèmes colorés',
      '11:58': 'Responsive design / Multi-ratio',
      '13:07': 'Gestion des polices',
      '13:46': 'Partage / Export d\'un template',
    },
  },
  {
    title: 'Les fondamentaux',
    id: 'PDoaQp_7jvc',
    chapters: {
      '00:00': 'Light vs Advanced mode',
      '02:13': 'Détection automatique des champs',
      '04:22': 'Ajout manuel de champs variables',
      '07:06': 'Support des objets graphiques essentiels',
      '11:31': 'Renommer un calque',
      '17:35': 'Règles généralistes des champs',
      '19:56': 'Règles spécifiques / types de champs',
      '45:02': 'Affichage conditionnel',
      '50:34': 'Chapitrage et miniatures ',
      '53:30': 'Test du template',
      '55:56': 'Création de la preview',
      '57:22': 'Sauvegarde de la configuration',
      '01:01:18': 'Enregistrement et utilisation dans la librairie',
      '01:03:55': 'Paramètres',
    },
  },
  {
    title: 'Les thèmes colorés',
    id: 'ouVnD62VETY',
    chapters: {
      '00:00': 'Création des contrôleurs locaux',
      '03:54': 'Création d\'un thème coloré',
      '08:21': 'Couleurs relatives',
      '12:07': 'Couleurs dérivées',
      '14:31': 'Couleurs statiques',
      '15:57': 'Création des miniatures',
      '17:37': 'Import / Export des thèmes colorés',
    },
  },
  {
    title: 'Les polices',
    id: 'X65HNINYDuc',
    chapters: {
      '00:00': 'Création des styles de texte',
      '01:07': 'Assignation des styles',
      '05:58': 'Test du formulaire',
    },
  },
  {
    title: 'Responsive design et template multi-ratio',
    id: 'C5Z3hWbANdY',
    chapters: {
      '00:00': 'Presets de redimensionnement',
      '05:05': 'Ratios switcher',
      '08:36': 'Alignement dynamique avec Danim Anchor',
      '10:15': 'Ratio d\'image dynamique',
      '14:56': 'Intégration du multi-ratio sur le formulaire',
    },
  },
  {
    title: 'Rigging avancé / Ajout et suppression de compositions',
    id: 'r4H5MSKzV70',
    chapters: {
      '00:00': 'Présentation du mode avancé',
      '03:23': 'Génération des miniatures',
      '05:17': 'Les compositions optionnelles ',
      '10:20': 'Zones dynamiques et compositions duplicables',
      '12:08': 'Test du formulaire',
    },
  },
  {
    title: 'Composition Controllers et comportements variables',
    id: '4vPlbo0jH5k',
    chapters: {
      '00:00': 'Présentation des contrôleurs de composition',
      '02:13': 'Modulo et Global index / Variations de couleurs',
      '07:56': 'Duplicated Index / Animations variables',
      '13:50': 'Test du formulaire',
    },
  },
  {
    title: 'Durée adaptative',
    id: 'Bco6UvLMErU',
    chapters: {
      '00:00': 'Duration Controller / Composition à durée variable',
      '05:31': 'Gestion des animations d\'entrée et de sortie par images clés',
      '07:14': 'Lier la durée d\'une composition à la longueur d\'un texte',
      '10:31': 'Test du formulaire',
      '11:17': 'Autoanimate / Animations adaptatives sans images clés',
    },
  },
  {
    title: 'Rendus multiples via CSV',
    id: 'pwo327rx4-8',
    chapters: {
      '00:00': 'Démonstration',
      '01:43': 'Création et fonctionnement du fichier CSV',
      '06:44': 'Rendu des vidéos',
    },
  },
  {
    title: 'Rendu CSV avancé',
    id: 'CCNO4xip5SM',
    chapters: {
      '00:00': 'Gestion des compositions optionnelles et dynamiques',
      '07:01': 'Modification du ratio, des couleurs et des polices via le CSV',
    },
  },
  {
    title: 'Import vidéo',
    id: 'oXphvSRsVM0',
    chapters: {
      '00:00': 'Démonstration',
      '00:37': 'Paramétrage d\'un champ vidéo',
      '01:55': 'Retiming auto d\'une composition',
      '07:57': 'Gestion du son des vidéos',
    },
  },
  {
    title: 'Danim Box - Partie 1',
    id: 'Vb-80sotCRQ',
    chapters: {
      '00:00': 'Présentation du script',
      '01:16': 'Installation du script',
      '02:24': 'Création d\'une TextBox',
      '04:23': 'Alignement de texte et Marges',
      '05:19': 'Les boxs variables',
      '06:04': 'Alignement de la box',
      '09:27': 'Animation d\'une TextBox',
      '12:36': 'Duplication d\'une box',
      '14:31': 'Lier plusieurs textes',
      '17:02': 'Le marqueur "static"',
      '18:52': 'Gestion des alphas',
      '21:23': 'Suppression d\'une box',
      '21:52': 'Format Lines',
      '23:27': 'Création d\'une ImageBox',
      '27:17': 'Animation simple d\'une ImageBox',
      '29:04': 'Animation automatique d\'une ImageBox',
      '31:41': 'Utilisation des boxs sur un projet existant',
    },
  },
  {
    title: 'Danim Box - Partie 2',
    id: 'iqjPhLq5bIU',
    chapters: {
      '00:00': 'Présentation de la V2',
      '04:55': 'Nouveautés TextBox',
      '10:33': 'Nouveautés ImageBox',
      '16:21': 'Danim Anchor : Accrocher un élément à une box',
      '21:38': 'Danim Anchor : Titrage responsive',
      '26:48': 'Multi Line Rig',
      '33:35': 'Quelques expressions pour aller plus loin...',
    },
  },
];

const enPlaylist = [
  {
    title: 'Overview',
    id: 'E_hVr0p1VtU',
    chapters: {
      '00:00': 'Introduction',
      '02:32': 'Video generator',
      '09:18': 'Render from a spreadsheet',
      '10:48': 'Template creation',
      '15:46': 'Exporting and rendering a template',
    },
  },
  {
    title: 'The Basics',
    id: 'wCXImhiB91A',
    chapters: {
      '00:00': 'Introduction',
      '00:32': 'Interface overview',
      '03:00': 'Light and advanced mode',
      '04:44': 'Layer detection',
      '09:33': 'Renaming layers',
      '14:56': 'General options',
      '18:47': 'Text type: Text',
      '22:41': 'Text type: Number',
      '26:35': 'Text type: Select',
      '30:30': 'Text type: Boolean',
      '33:30': 'Text type: Paragraph',
      '38:03': 'Images',
      '45:01': 'Videos',
      '48:44': 'Conditional rules',
      '54:04': 'Thumbnails',
      '55:35': 'Generation form',
      '59:13': 'Preview',
      '01:00:44': 'Saving your template',
      '01:02:50': 'The generator',
      '01:06:50': 'Conclusion',
    },
  },
  {
    title: 'Colored themes',
    id: 'zrVV-g6Xtx8',
    chapters: {
      '00:00': 'Introduction',
      '00:42': 'Create comp controllers',
      '03:16': 'Theme creation',
      '08:02': 'Relative colors',
      '09:51': 'Derivative colors',
      '11:21': 'Static colors',
      '12:29': 'Create thumbnails',
      '14:01': 'Import and export themes',
    },
  },
  {
    title: 'Fonts',
    id: 'F_QJRzs6ezk',
    chapters: {
      '00:00': 'Introduction',
      '00:27': 'Font rig creation',
      '04:25': 'Attributes',
      '06:32': 'dnm variable in expressions',
      '07:48': 'Font rig test',
    },
  },
  {
    title: 'Responsive design',
    id: 'WeL9iQN6OVg',
    chapters: {
      '00:00': 'Introduction',
      '00:45': 'Resizers',
      '02:53': 'Ratio switchers',
      '16:40': 'Dynamic image size',
      '18:58': 'Adding ratio switchers to the form',
    },
  },
  {
    title: 'Select, Boolean and expressions',
    id: 'Oj4mBWHao0w',
    chapters: {
      '00:00': 'Introduction',
      '00:39': 'Boolean',
      '03:37': 'Select',
    },
  },
  {
    title: 'Adaptive duration',
    id: 'lWGJsLdazDw',
    chapters: {
      '00:00': 'Introduction',
      '00:25': 'Free retime',
      '05:36': 'Variable video',
      '07:59': 'In & out keyframe animations',
      '11:02': 'Auto Animate',
      '17:43': 'Scripting composition duration',
    },
  },
  {
    title: 'Duplicable compositions',
    id: '2miW1CwBuAk',
    chapters: {
      '00:00': 'Introduction',
      '02:30': 'Thumbnails creation',
      '03:45': 'Optional composition',
      '05:07': 'Transitions between compositions',
      '08:12': 'Dynamic zone',
    },
  },
  {
    title: 'Composition controllers',
    id: 'r2RA5RSGRo0',
    chapters: {
      '00:00': 'Introduction',
      '02:37': 'Global index and color switch',
      '06:26': 'Duplicated index and variable animation',
    },
  },
  {
    title: 'Batch rendering with CSV',
    id: 'KsUwe2ZiqGQ',
    chapters: {
      '00:00': 'Introduction',
      '01:47': 'CSV Export',
      '07:42': 'CSV Import',
      '10:23': 'Rendering with CSV',
      '12:23': 'Multiple renders',
      '14:38': 'Google Sheet',
    },
  },
  {
    title: 'Advanced batch rendering',
    id: 'C2kcs0oEVrc',
    chapters: {
      '00:00': 'Introduction',
      '00:36': 'Optional composition and dynamic zone',
      '04:07': 'Metadata ratio',
      '06:39': 'Metadata color theme with variant index',
      '09:05': 'Metadata color theme with custom colors',
      '12:33': 'Metadata fonts',
    },
  },
  {
    title: 'Danim Box',
    id: 'xTK7TNL945g',
    chapters: {
      '00:00': 'Quick overview',
      '03:09': 'How to install the script?',
      '03:58': 'Text box creation',
      '05:54': 'Dynamic box',
      '07:54': 'Pin graphic elements with Danim Anchor',
      '12:14': 'Lower third creation with Danim Anchor',
      '14:34': 'Responsive design with Danim Anchor',
      '17:10': 'Text align',
      '18:46': 'Box align',
      '24:23': 'Text box animation',
      '27:18': 'Alpha creation',
      '28:32': 'Static marker',
      '29:51': 'Box duplication',
      '31:37': 'Copy & paste a box from one composition to another',
      '32:38': 'Box deletion',
      '33:06': 'Link several text sizes',
      '35:50': 'Multi line rig',
      '41:52': 'Useful expressions for multi line rig',
      '50:50': 'Auto line break with Format Lines',
      '52:40': 'Image box creation',
      '54:44': 'Image box animation',
      '57:08': 'Automatic image animation & Fullscreen box',
      '01:01:29': 'Danim Creator Beta',
    },
  },
];

function Guides() {
  const classes = useStyles();
  const [activeLang, setActivelang] = useState('en');

  const toggleActiveLang = () => {
    setActivelang(activeLang === 'fr' ? 'en' : 'fr');
  };

  return (
    <CreatorsSection 
      title="Guides"
      href="create-a-template"
      description={(
        <React.Fragment>
          {
            activeLang === 'fr' ? (
              <React.Fragment>
                Notre cours vidéo est aussi disponible en anglais. <Button color="secondary" className={classes.button} onClick={toggleActiveLang}>Regarder en anglais</Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                Our video course is also available in french. <Button color="secondary" className={classes.button} onClick={toggleActiveLang}>Watch in french</Button>
              </React.Fragment>
            )
          }
        </React.Fragment>
      )}
    >  
      <YoutubePlayer key={`yt-player-${activeLang}`} playlist={activeLang === 'fr' ? frPlaylist : enPlaylist} /> 
      <CreateTemplate />
      <UploadTemplate />
    </CreatorsSection>
  );
}

export default Guides;
