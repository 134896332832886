import React from 'react';
import { Typography } from '@material-ui/core';
import CreatorsSection from '../../../UX/CreatorsSection';
import ChangelogItem from './Changelog/ChangelogItem';

function Changelog() {
  return (
    <CreatorsSection 
      title="Changelog"
      href="changelog"
    >
      <Typography variant="body2" className="mb-2">
        Download or update Danim Creator at <a href="https://app.danim.com/creators" target="_blank" rel="noreferrer">https://app.danim.com/creators</a>.
      </Typography>
      <ChangelogItem version="0.9.84 - April 2022">
        <ul>
          <li>Monterey support</li>
          <li>Native ARM build</li>
          <li>Auto-updater for MacOS</li>
        </ul>
        <ul>
          <p>Fixes:</p>
          <ul>
            <li>Cannnot connect to danim.com server</li>
            <li>Cannot upload some large templates</li>
            <li>Cannot create new resolutions and thumbnails</li>
            <li>Auto-fade duration ignored in dnm-music</li>
          </ul>
        </ul>
      </ChangelogItem> 
      <ChangelogItem version="0.9.82 - March 2022 (Windows only)">
        <ul>
          <li>Auto-fade main music while playing an imported video</li>
          <li>Install / Open templates on Danim Creator from danim.com</li>
          <li>New placeholder option for text inputs</li>
          <li>PNG export in spreadsheet mode</li>
          <li>Custom fonts support on danim.com when uploading a template</li>
        </ul>
        <ul>
          <p>Fixes:</p>
          <ul>
            <li>CMJN images support</li>
            <li>S-Log video playback in video editor modal</li>
            <li>Disable audio checkbox in video editor modal</li>
            <li>Issues when searching videos on Pexels</li>
            <li>Bad composition duration with some imported videos</li>
            <li>Issues with thumbnails display and color theme creation</li>
          </ul>
        </ul>
        <p><strong>Only available for Windows at the moment</strong>, a new MacOS version is coming soon with:</p>
        <ul>
          <li>Monterey support</li>
          <li>Native ARM builds</li>
          <li>Software auto-updater</li>
        </ul>
      </ChangelogItem> 
      <ChangelogItem version="0.9.74 - November 2021">
        <ul>
          <li>Fix an issue when rendering in background on some fresh installs</li>
        </ul>
      </ChangelogItem> 
      <ChangelogItem version="0.9.73 - November 2021">
        <ul>
          <li>Fix issues with images and videos in spreadsheet mode</li>
          <li>Fix url and file links not working in macOS</li>
        </ul>
      </ChangelogItem> 
      <ChangelogItem version="0.9.72 - October 2021">
        <ul>
          <li>Fix autorig fails when some medias are offline</li>
          <li>Do not add layer "dnm-music" to first chapter in light mode</li>
          <li>Bad video range with Pexels and Giphy footages</li>
        </ul>
      </ChangelogItem> 
      <ChangelogItem version="0.9.70 - October 2021">
        <ul>
          <li>Use Danim cloud to render your templates rather than your own bot</li>
          <li>Import images and videos from Pexels, and gifs from Giphy, directly from the Danim form</li>
          <li>Import your own music and audio files into the Danim form, as full time music or to replace an audio layer</li>
          <li>New UI for CSV or Google Sheet jobs in render queue</li>
        </ul>
        <p>Fixes:</p>
        <ul>
          <li>Huge performance improvement when rendering a lot of videos from very large spreadsheet</li>
          <li>Show PNG renders in render queue</li>
          <li>Do not create more than 10 comps in dynamic zones with the random generator</li>
          <li>Detect PSD or AI layer and add a warning in the deep checker</li>
          <li>Render Quicktime Animation videos with alpha</li>
          <li>Fix thumbnail preview with optional comps</li>
        </ul>
      </ChangelogItem> 
      <ChangelogItem version="0.9.51 - September 2021">
        <p>Fixes:</p>
        <ul>
          <li>Bad layer out point and composition retiming when importing a video of variable duration</li>
          <li>Theme selection in CSV exporter doesn't work</li>
          <li>Thumbnail creation in light mode doesn't work</li>
          <li>Add a warning when a Google spreadsheet link is not valid</li>
        </ul>
      </ChangelogItem> 
      <ChangelogItem version="0.9.50 - July 2021">
        <ul>
          <li><strong>Create chapter thumbnails from danim.com</strong> with the bot</li>
          <li>Use new UPIA installer rather than ExManCmd to automatically install Danim Creator CEP extension</li>
        </ul>
        <p>Fixes:</p>
        <ul>
          <li>Upload template on danim.com even if main thumbnail is not defined</li>
          <li>Support duplicated compositions with same source in master composition with advanced mode</li>
          <li>Autorig error when a footage is added to form without having Danim ImageBox applied</li>
          <li>Bad renaming of removed image from form</li>
          <li>Auto clean composition names to get clean ids for API use: remove special characters (except - and _), accents, spaces, capital letters</li>
        </ul>
      </ChangelogItem> 
      <ChangelogItem version="0.9.40 - June 2021">
        <strong>A lot of fixes in this new release:</strong>
        <ul>
          <li>Allow incomplete templates to be uploaded: missing thumbnails, missing video preview, empty composition or input description, etc</li>
          <li>Bad duration for videos imported with CSV or Google Sheet</li>
          <li>Bad UTF-8 characters with CSV or Google Sheet</li>
          <li>Relink project dialog doesn't work when opening CJSON file</li>
          <li>Bad interpolation for keys moved by the retimer</li>
          <li>Thumbnails are not updated in light mode and into the test form</li>
          <li>Round the duration to the nearest frame when retiming a composition</li>
          <li>Blank screen on launch in some rare cases</li>
          <li>Mute videos in the library on hover</li>
        </ul>
      </ChangelogItem> 
      <ChangelogItem version="0.9.30 - May 2021">
        <p>This release is the first major update. We are really excited to introduce the cloud features. Now, you can use you Danim template online and render your videos from anywhere!</p>
        <strong>Main features:</strong>
        <ul>
          <li>Upload your templates to danim.com and render videos from your web browser</li>
          <li>Public library on the home page to try and inspect some of our templates</li>
          <li>New "Helper" section: Automatically find issues in your aep project and missing data in your template configuration</li>
          <li>"Open in After Effects" feature for installed templates</li>
          <li>Open the templates you have previously uploaded on danim.com</li>
          <li>View render progress in task bar</li>
          <li>"Full time" mode in Auto Animate to animate a property during all the layer duration</li>
          <li>Many UX improvements</li>
        </ul>
        <strong>Dozens of fixes, here are the main ones:</strong>
        <ul>
          <li>Some thumbnails were not updated</li>
          <li>Do not collect modified, invisible or close colors with "Create comp controllers"</li>
          <li>Add black or white background when cropping PNG</li>
          <li>Some layers were removed from the configuration after a renaming</li>
          <li>Support "Fit" method from Danim ImageBox with the cropper</li>
          <li>Several issues with the comp retimer</li>
          <li>Do not retime the source of compositions with time remapping enabled</li>
          <li>Do not use default value in CSV mode</li>
        </ul>
      </ChangelogItem> 
      <ChangelogItem version="0.9.11 - February 2021">
        <ul>
          <li>Import data from a Google Sheet public link</li>
          <li>"AEP Only" export option in "single rendering" and CSV mode</li>
          <li>Add comment line in CSV</li>
          <li>Fix AE crash when a variable layer is in several precomps</li>
          <li>Fix bad crop for some images</li>
          <li>Fix wrong values for select and boolean inputs in CSV mode</li>
          <li>Fix template saving when some thumbnails are missing</li>
          <li>Fix useless error warning after preview rendering</li>
          <li>Fix ratio switcher expression error on slider and 1 dimension parameters</li>
          <li>Fix issue with transcoding after the first FFmpeg install</li>
          <li>Remove special characters and spaces when using the layer or composition renamer</li>
          <li>Add warning when .psd or .ai files are used in the template</li>
          <li>Exclude the line break option from parenting</li>
          <li>Don't show "Automatically format" when Format Lines is not used</li>
          <li>Don't retime the source of composition layer with "Time Remapping" enabled when using the Retimer</li>
        </ul>
      </ChangelogItem>
      <ChangelogItem version="0.9.10 - January 2021">
        <ul>
          <li>Batch render thanks to CSV import</li>
          <li>Better video import with cut features</li>
          <li>macOS installer can now be executed by double clicking, no need to authorize the app anymore</li>
          <li>Add manual installation instructions in case of failure during Danim Creator AE API extension installation</li>
          <li>Add a divider option between inputs</li>
          <li>Update Troubleshooting section in the documentation</li>
        </ul>
      </ChangelogItem>
      <ChangelogItem version="0.9.0">
        Public beta launch
      </ChangelogItem>
    </CreatorsSection>
  );
}

export default Changelog;
