import React from 'react';
import { Typography } from '@material-ui/core';
import CreatorsSection from '../../../UX/CreatorsSection';
import Note from '../../../UX/Note';
import aePrefsImg from '../../../../../assets/images/creators/troubleshooting/ae-prefs.png';
import disableBgRender from '../../../../../assets/images/creators/troubleshooting/disable-background-rendering.png';
import macosPermissionsImg from '../../../../../assets/images/creators/troubleshooting/macos-permissions.png';

function Troubleshooting() {
  return (
    <CreatorsSection 
      title="Troubleshooting"
      href="troubleshooting"
    >
      <div id="creator-ae-bridge-troubleshooting">
        <Typography variant="overline" className="colorSecondary w-bold">Danim Creator AE API installation</Typography>
        <p>Danim Creator needs to install an extension to communicate with After Effects. If the installation fails, please check these points or install it manually.</p>
        <ul className="creatorsList">
          <li>Adobe Creative Cloud app has to be up to date. CC 5.3 or above is needed.</li>
          <li>Restart as administrator</li>
          <li>Check that ExManCmd is not blocked by your antivirus or firewall</li>
        </ul>
        <Note status="info" title="Manual installation" className="mb-2">
          If automatic installation fails, please install the extension manually. In the error dialog, click on "Manual Installation" and choose a folder to save com.danim.creator.api.zxp extension. Then install this zxp like any other Adobe extension, with <a href="https://aescripts.com/learn/zxp-installer/" target="_blank" rel="noreferrer">ZXP Installer from aescripts</a> or <a href="https://install.anastasiy.com" target="_blank" rel="noreferrer">Anastasiy’s Extension Manager</a>. If the error dialog persists after Danim Creator restart, please <a onClick={() => window.openContactWidget('')} href="javascript:void(0)">contact us here</a>.
        </Note>
      </div>
      <div className="pt-2">
        <Typography variant="overline" className="colorSecondary w-bold">Communications between Danim Creator and After Effects</Typography>
        <ul className="creatorsList">
          <li>After Effects has to be licensed. You have to be connected to Creative Cloud.</li>
          <li>Do not use After Effects 17.5 as AE background render engine. This version has several issues with background rendering. So update to latest AE version, or define another target engine in Danim Creator settings. <a href="https://adobe-video.uservoice.com/forums/911311-after-effects/suggestions/43509876-aerender-fails-on-mac-os-11-4-with-error-1701-ae" target="_blank" rel="noreferrer">Some issues</a> have also been reported with 18.1, 18.2 and 18.4 versions on macOS.</li>
          <li>
            Check the box called "Allow Scripts to write files..." in the section "Script & Expressions" of your AE preferences.
            <img src={aePrefsImg} alt="After Effects preferences" className="creatorsMediumImg mt-2 mb-2" />
          </li>
          <li>
            In macOS, check all permissions asked by Danim Creator in Settings > Security > Privacy > Automation, and restart Danim Creator.
            <img src={macosPermissionsImg} alt="macOS permissions" className="creatorsSmallImg w-full mt-2 mb-2" />
          </li>
        </ul>
        <Note status="info" title="Disable background rendering" className="mb-2">
          If you still have problems with After Effects rendering in the background, or if you need to use an incompatible version, you can disable background rendering in the Danim Creator settings.
          <img src={disableBgRender} alt="Disable background rendering" className="mt-2" />
        </Note>
      </div>
      <div>
        <Typography variant="overline" className="colorSecondary w-bold">FFmpeg transcoding error</Typography>
        <ul className="creatorsList">
          <li>If you encounter the error "Permission denied", please check that FFmpeg is not blocked by your antivirus or firewall</li>
        </ul>
      </div>
    </CreatorsSection>
  );
}

export default Troubleshooting;
