import React from 'react';
import CodeBlock from '../../../../UX/CodeBlock';

function ApiReferenceBodyExamples(props) {
  const { examples } = props;
  const example = examples[Object.keys(examples)[0]];
  return (
    <div className="mb-2">
      <CodeBlock language="json" title="Request example">
        {JSON.stringify(typeof example.value === 'string' || example.value instanceof String ? JSON.parse(example.value) : example.value, null, 2)}
      </CodeBlock>
    </div>
  );
}

export default ApiReferenceBodyExamples;
