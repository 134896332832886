const creatorNavList = [
  {
    id: 'introduction',
    label: 'Introduction',
  },
  {
    id: 'prerequisite',
    label: 'Prerequisites',
  },
  {
    id: 'how-to-install',
    label: 'How to install?',
  },
  {
    id: 'troubleshooting',
    label: 'Troubleshooting',
  },
  {
    id: 'guides',
    label: 'Guides',
    list: [
      {
        id: 'create-a-template',
        label: 'Create and share a template',
      },
      {
        id: 'upload-a-template',
        label: 'Upload a template to danim.com',
      },
    ],
  },
  {
    id: 'changelog',
    label: 'Changelog',
  },
];
  
export default creatorNavList;
