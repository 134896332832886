import React from 'react';
import apiReference from '../../../../utilities/apiReference';
import ApiSection from '../../../UX/ApiSection';
import ApiReferenceRoute from './ApiReference/ApiReferenceRoute';
import ApiReferenceReadRoute from './ApiReference/ApiReferenceReadRoute';

function ApiReference() {
  return (
    <ApiSection 
      title="API Reference"
    >
      {
          apiReference.map((reference) => (
            <ApiReferenceRoute details={reference} key={reference.id} />
          ))
      }
      <ApiReferenceReadRoute />
    </ApiSection>
  );
}

export default ApiReference;
