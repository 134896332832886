import React from 'react';
import { Stepper, Step, StepLabel, StepContent, Typography, Link } from '@material-ui/core';
import CreatorsSection from '../../../../UX/CreatorsSection';
import step1 from '../../../../../../assets/images/creators/create-template/create-template-step1.jpg';
import step2 from '../../../../../../assets/images/creators/create-template/create-template-step2.jpg';
import step3 from '../../../../../../assets/images/creators/create-template/create-template-step3.jpg';
import step4 from '../../../../../../assets/images/creators/create-template/create-template-step4.jpg';
import step5 from '../../../../../../assets/images/creators/create-template/create-template-step5.jpg';
import ShortCode from '../../../../UX/ShortCode';

function CreateTemplate() {
  return (
    <CreatorsSection 
      title="Create and share a template"
      href="create-a-template"
      isSubSection
      hideDivider
      description={(
        <React.Fragment>
          Step by step, discover how to transform your After Effects project into a shareable template.
        </React.Fragment>
      )}
    > 
      <Stepper orientation="vertical" className="createVideoGuideSection">
        <Step active>
          <StepLabel className="stepLabel">Autorig your composition</StepLabel>
          <StepContent>
            <img className="createTemplateStepImg mb-2" src={step1} />
            Launch After Effects and open your project. In Danim Creator, go to <strong>Build</strong> panel <ShortCode type="success" light>1</ShortCode>, find your composition by its name <ShortCode type="success" light>2</ShortCode>, select "Light" mode and launch the autorig <ShortCode type="success" light>3</ShortCode>.
            <br /><br />Every change you make in your template is auto-saved by Danim Creator. You can reopen your autosaves in section <ShortCode type="success" light>4</ShortCode>. Remember to manually save your work regularly, because there isn't any history in the autosave.
          </StepContent>
        </Step>
        <Step active>
          <StepLabel className="stepLabel">Basic configuration</StepLabel>
          <StepContent>
            <img className="createTemplateStepImg" src={step2} />

            <Typography variant="h6" className="mt-2 mb-1">How the autorig works?</Typography>
            When you open a composition with Danim Creator, it tries to automatically find some variable inputs during the <strong>autorig</strong> process. All the text and footage layers (and master properties) with the prefix <ShortCode light>dnm-</ShortCode> (dnm-mysupertextlayer for example) will be added to your form. Same thing for every layer which has Danim TextBox or Danim ImageBox effect, that's why we highly suggest you to use it.

            <Typography variant="h6" className="mt-2 mb-1">Add new layers and create chapters</Typography>
            You can also add selected layers manually by clicking on button <ShortCode type="success" light>6</ShortCode> and <strong>"Add selected layers"</strong>.
            <br />Your layers are in the same chapter by default. You can create a new one with button <ShortCode type="success" light>5</ShortCode>. Then, you just have to drag and drop your inputs from one chapter to another.

            <Typography variant="h6" className="mt-2 mb-1">Create / Update a chapter thumbnail</Typography>
            Open your composition in After Effects, go to the targeted time, click on button <ShortCode type="success" light>6</ShortCode> and click on "Create / Update thumbnail" for each chapter. When you will use the function "Update all thumbnails" in the future, all thumbnails in your template will be updated at the time defined during this step.

            <Typography variant="h6" className="mt-2 mb-1">Edit an input label</Typography>
            Click on the input <ShortCode type="success" light>7</ShortCode> and edit the description. Then, you'll see the form changing in realtime in the section <ShortCode type="success" light>8</ShortCode>. This section in the right side of your screen (or in the next tab in small screen mode) lets you test your form during the whole creation process.
            <br />To edit the other settings of your input, click on the arrow <ShortCode type="success" light>9</ShortCode>.
          </StepContent>
        </Step>
        <Step active>
          <StepLabel className="stepLabel">Input settings</StepLabel>
          <StepContent>
            <img className="createTemplateStepImg mb-2" src={step3} />
            There are <strong>a lot</strong> of settings in Danim Creator for your text and footage inputs. For a text input, start by selecting the layer type in section <ShortCode type="success" light>10</ShortCode> from these choices: text, number, paragraph, select and boolean. Each input type has several specific rules editable in the section <ShortCode type="success" light>11</ShortCode>. Please watch our video course to know more about that.
          </StepContent>
        </Step>
        <Step active>
          <StepLabel className="stepLabel">Save your template</StepLabel>
          <StepContent>
            <img className="createTemplateStepImg mb-2" src={step4} />

            <Typography variant="h6" className="mt-2 mb-1">Save the configuration</Typography>
            Before saving your template to your library, create the video preview by clicking on "Update preview" <ShortCode type="success" light>12</ShortCode>. The render is done in background, so you can continue your work during that. Take this opportunity to save your configuration file (<strong>.cjson</strong>) with "Save configuration" <ShortCode type="success" light>13</ShortCode> or <ShortCode type="success" light>Ctrl+S</ShortCode>. Next time you want to edit this template, just double click on your .cjson file.

            <Typography variant="h6" className="mt-2 mb-1">Save the template in your library</Typography>
            The .cjson configuration is used to work on the template, not to create videos with the form. To do that, click on "Save to library" <ShortCode type="success" light>14</ShortCode>, set a title and a few tags, and validate. 
          </StepContent>
        </Step>
        <Step active>
          <StepLabel className="stepLabel">Use and share a template</StepLabel>
          <StepContent>
            <img className="createTemplateStepImg mb-2" src={step5} />
            <Typography variant="h6" className="mt-2 mb-1">Open your template</Typography>
            Your template is now saved in the database, with all the needed medias, so if you edit your aep project, your template will not be modified. Find your template by typing its title in the search engine <ShortCode type="success" light>15</ShortCode>. Then, click on the thumbnail, and voila! You can now create all the videos you want from this template.
            <Typography variant="h6" className="mt-2 mb-1">Send it to a friend</Typography>
            If you want to share it with a friend, click on the "Export" icon <ShortCode type="success" light>16</ShortCode> and choose the destination. A .danim file will be created, and your friend will just have to drop it in Danim Creator window to use it.
          </StepContent>
        </Step>
        <Step active>
          <StepLabel className="stepLabel">Upload your template to danim.com</StepLabel>
          <StepContent>
            <Link href="#upload-a-template">Learn how to upload your template to danim.com</Link> and start creating videos from your web browser.
          </StepContent>
        </Step>
        <Step active>
          <StepLabel className="stepLabel">What's next?</StepLabel>
          <StepContent>
            You have yet dozens of other features to discover. Our video course will learn you how to use all these features: color themes, multi-ratio, font selector, advanced rigging mode to make your compositions reorderable... 
          </StepContent>
        </Step>
      </Stepper> 
    </CreatorsSection>
  );
}

export default CreateTemplate;