import React from 'react';
import { 
  Typography,
} from '@material-ui/core';
import ApiSection from '../../../../UX/ApiSection';
import Note from '../../../../UX/Note';
import CodeBlock from '../../../../UX/CodeBlock';
import Table from '../../../../UX/Table';
import ShortCode from '../../../../UX/ShortCode';

const errorsTable = {
  columns: [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'description',
      label: 'Description',
    },
  ],
  rows: [
    {
      name: 'InvalidJson',
      description: 'The JSON POST body cannot be parsed. This might be because it’s actually not JSON, or perhaps you did not correctly set your HTTP Content-Type header. Also make sure your JSON attribute keys are strings wrapped with double-quote characters.',
    },
    {
      name: 'MalformedUtf8Characters',
      description: 'We understood that your code was JSON-like enough to parse it, but some character may have been incorrectly encoded.',
    },
  ],
};

function ContentType() {
  return (
    <ApiSection 
      title="Content-Type"
      href="content-type"
      description={(
        <React.Fragment>
          When requesting via HTTP POST, you may present your arguments as either standard <ShortCode>application/x-www-form-urlencoded</ShortCode> POST parameters 
          in your Content-Type header or use JSON <ShortCode>application/json</ShortCode> instead.
        </React.Fragment>
          )}
    >
      <Typography variant="body2">
        Note that when dealing with file(s) upload, you’ll will not be able to use <ShortCode>application/x-www-form-urlencoded</ShortCode>, 
        but be compelled to use <ShortCode>multipart/form-data</ShortCode> instead. If you really want to avoid the multipart form when providing files, 
        or for any other reason, you can provide them with URLs (of your own cloud, for instance) or preloaded-file IDs. 
        You'll find more informations about that in <a href="#introduction" className="colorLight">the dedicated section</a>.
      </Typography>
      <Note className="mt-2 mb-2" title="Warning" status="warning">
        <Typography variant="body2" color="textSecondary">
          When using <ShortCode light>Content-Type: multipart/form-data</ShortCode>, don’t forget to provide the boundary (<a href="https://developer.mozilla.org/fr/docs/Web/HTTP/Headers/Content-Type" rel="noreferrer" target="_blank">see details here</a>). 
          Some tools automatically calculate it (e.g. common browsers, Postman, etc.), but you could also provide an empty 
          Content-Type header, and we’ll guess what you meant by this.
        </Typography>
      </Note>
      {
        // URL-encoded bodies
        }
      <Typography variant="h5" className="colorSecondary mt-2 pt-2">
        URL-encoded bodies
      </Typography>
      <Typography variant="body2" className="mt-2">
        When sending URL-encoded data, set your HTTP Content-Type header to <ShortCode>application/x-www-form-urlencoded</ShortCode> 
        and present your key/value pairs according to <a href="https://tools.ietf.org/html/rfc3986" target="_blank" rel="noreferrer">RFC-3986</a>.
        For example, a video generation might look something like this:
      </Typography>
      <CodeBlock codeKey="apiContentTypeUrlEncoded" language="plaintext" className="mt-1 mb-1" />
      {
          // JSON-encoded bodies
      }
      <Typography variant="h5" className="colorSecondary mt-2 pt-2">
        JSON-encoded bodies
      </Typography>
      <div className="mt-2" />
      <Typography variant="body2" component="span">
        But you may alternatively send your HTTP POST data with header <ShortCode>Content-Type: application/json</ShortCode>.
        <br /><br />
        There are some ground rules: 
        <ul>
          <li>you must explicitly set the Content-Type HTTP header to application/json. We won’t interpret your POST body as such without it.</li>
          <li>you cannot send your credentials as an attribute in your posted JSON.</li>
          <li>do not mix arguments up, between URL-encoded and JSON attributes. Choose one approach per request.</li>
          <li>providing an explicitly null value for an attribute will result in whichever default behavior is assigned to it.</li>
        </ul>
        For example, to send the same request than above with a JSON POST body:
      </Typography>
      <CodeBlock codeKey="apiContentTypeJsonEncoded" language="json" className="mt-1 mb-1" />
      {
          // Errors specific to JSON
      }
      <Typography variant="h5" className="colorSecondary mt-2 pt-2">
        Errors specific to JSON
      </Typography>
      <Typography variant="body2" className="mt-2">
        If the posted JSON is invalid, you’ll receive one of the following errors (<ShortCode>meta.type</ShortCode>) in response (<a href="#evaluating-responses" className="colorLight">see response details below</a>):
        In all cases, you’ll need to revise your JSON or how you’re transmitting your data to resolve the error condition.
      </Typography>
      <Table 
        table={errorsTable} 
        className="mt-2" 
        caption="In all cases, you’ll need to revise your JSON or how you’re transmitting your data to resolve the error condition." 
      />
    </ApiSection>
  );
}

export default ContentType;
