import React, { useMemo } from 'react';
import { Typography, Grid, Card, CardContent, Divider } from '@material-ui/core';
import { get } from 'lodash-es';
import ApiSection from '../../../../UX/ApiSection';
import Markdown from '../../../../UX/Markdown';
import ApiReferenceAttributes from './ApiReferenceAttributes';
import ApiReferenceBodyExamples from './ApiReferenceBodyExamples';
import ApiReferenceResponseExamples from './ApiReferenceResponseExamples';
import ApiReferenceErrorsTable from './ApiReferenceErrorsTable';

function ApiReferenceRoute(props) {
  const { details } = props;
  const { id, route, summary, description, body, response } = details;

  const dataSchema = useMemo(() => {
    const schema = get(response, 'variants.success.data');
    if (schema && schema.properties && Object.keys(schema.properties).length > 0) return schema;
    return null;     
  }, [details]);

  const errorSchema = get(response, 'variants.error');
  
  return (
    <ApiSection 
      title={route}
      href={id}
      isSubSection
      description={summary}
      lightTitle
    >
      { description && (
        <Typography variant="body2" component="span">
          <Markdown content={description} />
        </Typography>
      )}
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12} lg={6}>
          <div className="autoSticky">
            <Card className="mb-2">
              <CardContent>
                <div className="pt-1 pb-2">
                  <Typography variant="h6">Request parameters</Typography>
                  <div>
                    <Typography variant="overline">Method: </Typography>
                    <Typography variant="caption" className="colorLight">POST</Typography>
                  </div>
                  <div>
                    <Typography variant="overline">Content-Type: </Typography>
                    <Typography variant="caption" className="colorLight">{body.contentType}</Typography>
                  </div>
                </div>
                <Divider variant="inset" className="mb-2" />
                <ApiReferenceAttributes schema={body.schema} />
              </CardContent>
            </Card>
            { dataSchema && (
              <Card className="mb-2">
                <CardContent>
                  <div className="pt-1 pb-2">
                    <Typography variant="h6">Response data (<span className="colorLight">success</span>)</Typography>
                  </div>
                  <Divider variant="inset" className="mb-2" />
                  <ApiReferenceAttributes schema={dataSchema} />
                </CardContent>
              </Card>
            )}
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className="autoSticky">
            { body?.contentType === 'application/json' && (
            <ApiReferenceBodyExamples examples={body.examples} />
            )}
            <ApiReferenceResponseExamples examples={response.examples} />
          </div>
        </Grid>
      </Grid>
      { errorSchema && <ApiReferenceErrorsTable errorSchema={errorSchema} /> }
    </ApiSection>
  );
}

export default ApiReferenceRoute;
