import React from 'react';

class ErrorCatcher extends React.Component {
  static getDerivedStateFromError() {
    // TODO: Error fallback UI
    location.reload();
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }
  
  render() {
    const { children } = this.props;
    return children;
  }
}

export default ErrorCatcher;
