import YouTube from 'react-youtube';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 500,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: 250,
    background: theme.palette.background.dark,
  },
}));

function YoutubePlayer(props) {
  const { playlist } = props;
  const classes = useStyles();
  const [activeVideoIndex, setActiveVideoIndex] = React.useState(0);
  const [activePlayer, setActivePlayer] = React.useState(null);
  
  const video = playlist[activeVideoIndex];

  const chapters = React.useMemo(() => {
    const newChapters = [];
    for (const key in video.chapters) {
      const parts = key.split(':');
      let seconds = 0;
      if (parts.length === 3) {
        seconds = parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
      } else if (parts.length === 2) {
        seconds = parseInt(parts[0]) * 60 + parseInt(parts[1]);
      }
      newChapters.push({ seconds, time: key, label: video.chapters[key] });
    }
    return newChapters;
  }, [video]);

  const handleChange = (event, newValue) => {
    setActiveVideoIndex(newValue);
  };

  const handlePlayerReady = (e) => {
    setActivePlayer(e.target);
  };

  const handleChapterClick = (chapter) => {
    if (activePlayer) activePlayer.seekTo(chapter.seconds);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={activeVideoIndex}
        onChange={handleChange}
        className={`${classes.tabs} ytChaptersTabs`}
      >
        {
              playlist.map(video => (
                <Tab key={video.id} label={video.title} />
              ))
          }
      </Tabs>
      <Box p={3} className="ytCreatorBox">
        <div className="ytCreatorContainer">
          <YouTube
            videoId={video.id} // defaults -> null
            onReady={handlePlayerReady} 
            containerClassName="ytCreatorPlayer"
          />
        </div>
        <div className="mt-2">
          {
                chapters.map((chapter, index) => (
                  <div className="ytCreatorChapter" key={index}>
                    <Typography variant="caption">
                      <span className="mr-05">{chapter.time}</span><Link className="cursor" onClick={() => handleChapterClick(chapter)}>{chapter.label}</Link>
                    </Typography>
                  </div>
                ))
            }
        </div>
      </Box>
    </div>
  );
}

// function YoutubePlayer() {
//   return (
      
//     <YouTube
//       videoId={string} // defaults -> null
//       id={string} // defaults -> null
//       className={string} // defaults -> null
//       containerClassName={string} // defaults -> ''
//       opts={obj} // defaults -> {}
//       onReady={func} // defaults -> noop
//       onPlay={func} // defaults -> noop
//       onPause={func} // defaults -> noop
//       onEnd={func} // defaults -> noop
//       onError={func} // defaults -> noop
//       onStateChange={func} // defaults -> noop
//       onPlaybackRateChange={func} // defaults -> noop
//       onPlaybackQualityChange={func} // defaults -> noop
//     />
//   );
// }

export default YoutubePlayer;
