import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/prism-light';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import graphql from 'react-syntax-highlighter/dist/esm/languages/prism/graphql';
import dracula from 'react-syntax-highlighter/dist/esm/styles/prism/dracula';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';
import codeSections from '../../code/codeSections';

SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('graphql', graphql);

const useStyles = makeStyles((theme) => ({
  darkBackground: {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
  lightBackground: {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  titleDiv: {
    padding: '3px 10px 0 10px',
  },
}));

const CodeBlock = (props) => {
  const { children, language, className, codeKey, light, showLineNumbers, title } = props;
  const classes = useStyles();

  const backgroundClass = light ? classes.lightBackground : classes.darkBackground;
  
  return (
    <div className={className || null}>
      {
        title && (
          <React.Fragment>
            <div className={clsx(classes.titleDiv, backgroundClass)}>
              <Typography variant="overline" className={clsx('colorSecondary', 'm-0', 'w-bold')}>
                { title }
              </Typography>
            </div>
            <Divider variant="inset" />
          </React.Fragment>
        )
      }
      <SyntaxHighlighter showLineNumbers={showLineNumbers} className={clsx(backgroundClass, 'm-0', title && 'pt-1')} language={language === 'plaintext' ? null : language} style={dracula}>
        { codeKey ? codeSections[codeKey] : children }
      </SyntaxHighlighter>
    </div>
  );
};

CodeBlock.defaultProps = {
  language: 'json',
  className: '',
  showLineNumbers: false,
  title: null,
  light: false,
};

export default CodeBlock;
