import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Collapse, Icon } from '@material-ui/core';
import clsx from 'clsx';
import useToggle from '../../utilities/hooks/useToggle';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '20px',
  },
  nested: {
    paddingLeft: '5px',
    backgroundColor: theme.palette.background.dark,
  },
  activeListItem: {
    color: theme.palette.primary.light,
  },
  unactiveListItem: {
    color: theme.palette.text.secondary,
  },
  activeLinkItem: {
    backgroundColor: theme.palette.background.light,
  },
  listItem: {
    '&:hover': {
      background: theme.palette.background.light,
    },
  },
  listDiv: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  listItemText: {
    fontSize: '1rem !important',
  },
}));

function Nav(props) {
  const { activeId, list, projection, onLinkClick } = props;
  const classes = useStyles();
  const deps = useMemo(() => {
    for (let i = 0; i < projection.length; i++) {
      const { id, deps: _deps } = projection[i];
      if (id === activeId) {
        return _deps || [];
      }
    }
    return [];
  }, [activeId, projection]);
  return (
    <NavList list={list} activeId={activeId} deps={deps} className={classes.root} onLinkClick={onLinkClick} />
  );
}

function NavList(props) {
  const { list, activeId, nested, deps, className, onLinkClick } = props;
  const classes = useStyles();
  return (
    <List className={clsx(classes.listDiv, nested && classes.nested, className)}>
      {
            list.map((item, index) => (
              <NavItem {...item} activeId={activeId} key={index} deps={deps} onLinkClick={onLinkClick} />
            ))
        }
    </List>
  );
}

function NavItem(props) {
  const [open, , toggleOpen] = useToggle(false);
  const classes = useStyles();
  const { label, list, activeId, id, deps, onLinkClick } = props;

  let isActive = false;
  let forceOpen = false;
  if (activeId === id) isActive = true;
  else if (deps.indexOf(id) !== -1) {
    isActive = true;
    forceOpen = true;
  }

  const _onLinkClick = () => {
    if (onLinkClick) onLinkClick(id);
  };

  const listItem = (
    <ListItem button onClick={!forceOpen ? toggleOpen : null} className={clsx(classes.listItem, classes.listDiv, isActive ? classes.activeListItem : classes.unactiveListItem, isActive && !list && classes.activeLinkItem)}>
      <ListItemText primary={label} className={classes.listItemText} disableTypography />
      { list && <Icon>{open || forceOpen ? 'expand_less' : 'expand_more'}</Icon>}
    </ListItem>
  );
  return (
    <React.Fragment>
      {
        list ? listItem : (
          <a href={`#${id}`} onClick={_onLinkClick}>
            {listItem}
          </a>
        )
      }
      {
          list && (
            <Collapse in={open || forceOpen} timeout="auto" unmountOnExit>
              <NavList list={list} activeId={activeId} nested deps={deps} onLinkClick={onLinkClick} />
            </Collapse>
          )
       }
    </React.Fragment>
  );
}

export default Nav;
