import React from 'react';
import ContentType from './Basics/ContentType';
import EvaluatingResponses from './Basics/EvaluatingResponses';
import RateLimits from './Basics/RateLimits';

function Basics() {
  return (
    <React.Fragment>
      <ContentType />
      <EvaluatingResponses />
      <RateLimits />
    </React.Fragment>
  );
}

export default Basics;
