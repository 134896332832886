import { get, find } from 'lodash-es';
import openApiJson from '../../assets/json/dnm-open-api.json';
import objectToArray from './objectToArray';

const apiReference = [];
const { paths } = openApiJson;
for (const route in paths) {
  const { post } = paths[route];
  if (post) {
    const { description, operationId, requestBody, responses, summary } = post;

    let body = {
      description: requestBody.description,
    };
    for (const contentType in requestBody.content) {
      const properties = get(requestBody.content[contentType], 'schema.properties') || {};
      if (Object.keys(properties).length > 0) {
        const { schema, examples } = requestBody.content[contentType];
        body = {
          ...body,
          contentType,
          schema,
          examples,
        };
        break;
      }
    }

    let response = null;
    if (responses[200]) {
      for (const contentType in responses[200].content) {
        const allResponses = get(responses[200].content[contentType], 'schema.oneOf') || {};
        const data = find(allResponses, { description: 'success' });
        const errors = find(allResponses, { description: 'error' });
        if (data && errors) {
          response = {
            variants: {
              success: data.properties, 
              error: errors.properties,
            },
            examples: objectToArray(responses[200].content[contentType].examples),
          };
          break;
        }
      }
    }
    const reference = {
      id: `reference-${operationId}`,
      route,
      domain: route.split('/')[route.split('/')[1] === 'write' ? 2 : 1],
      description,
      body,
      response,
      summary,
    };
    apiReference.push(reference);
  }
}

export default apiReference;
