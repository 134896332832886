import React from 'react';
import { Typography } from '@material-ui/core';
import ApiSection from '../../../UX/ApiSection';
import ShortCode from '../../../UX/ShortCode';
import Table from '../../../UX/Table';
import CodeBlock from '../../../UX/CodeBlock';

const errorsTable = {
  columns: [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'description',
      label: 'Description',
    },
  ],
  rows: [
    {
      name: 'AuthenticationRequired',
      description: 'Missing app ID',
    },
    {
      name: 'AppDoesNotExist',
      description: 'The app does not exist',
    },
    {
      name: 'InvalidAppSecret',
      description: 'The provided app secret is not correct',
    },
  ],
};

function Authentication() {
  return (
    <ApiSection 
      title="Authentication"
      href="auth"
    >
      <Typography variant="body2">
        We provide you app credentials, which consist of two keys, a public ID and a private secret. 
        You must keep the secret out of sight. Go to API settings to get your credentials: <a rel="nofollow" target="_blank" href="https://app.danim.com/organization/api">app.danim.com/organization/api</a>
      </Typography>
      {
        // CREDENTIALS
      }
      <Typography variant="h5" className="colorSecondary mt-2 mb-2 pt-2">
        Credentials
      </Typography>
      <Typography variant="body2" component="span">
        You have 3 ways to provide them in each request:
        <ul>
          <li>
            <strong>Extra non-standard X-foo-bar headers</strong>, the request containing two headers 
            in the form of <ShortCode>X-App-Id: {'<ID>'}</ShortCode> and <ShortCode>X-App-Secret: {'<secret>'}</ShortCode>.
          </li>
          <li>
            <strong>Basic HTTP authentication</strong>, the request containing a header in the form of 
            <ShortCode>Authorization: Basic {'<credentials>'}</ShortCode>, where credentials is the Base64 encoding of <ShortCode>{'<ID>'}:{'<secret>'}</ShortCode> 
            (ID and secret joined by a single colon "<ShortCode>:</ShortCode>", <a href="https://en.wikipedia.org/wiki/Basic_access_authentication" target="_blank" rel="noreferrer">see RFC 7617</a>).
          </li>
          <li>
            <strong>Query parameters</strong>, in the form of 
            <ShortCode>appId={'<ID>'}</ShortCode> and <ShortCode>appSecret={'<secret>'}</ShortCode>.
          </li>
        </ul>
      </Typography>
      {
        // Related errors
      }
      <Typography variant="h5" className="colorSecondary mt-2 pt-2">
        Related errors
      </Typography>
      <Table 
        table={errorsTable} 
        className="mt-2" 
      />
      {
        // Examples
      }
      <Typography variant="h5" className="colorSecondary mt-2 pt-2">
        Examples
      </Typography>
      <CodeBlock codeKey="apiAuthenticationBase64Example" language="plaintext" title="Basic HTTP authentication" className="mt-2 mb-2" />
      <CodeBlock codeKey="apiAuthenticationHeadersExample" language="plaintext" title="Extra headers" className="mt-2 mb-2" />
      <CodeBlock codeKey="apiAuthenticationQueryParametersExample" language="plaintext" title="Query parameters" className="mt-2 mb-2" />
    </ApiSection>
  );
}

export default Authentication;
