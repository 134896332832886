import React from 'react';
import { Typography } from '@material-ui/core';
import ApiSection from '../../../UX/ApiSection';
import ShortCode from '../../../UX/ShortCode';
import Note from '../../../UX/Note';
import CodeBlock from '../../../UX/CodeBlock';
import Table from '../../../UX/Table';

const errorsTable = {
  columns: [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'description',
      label: 'Description',
    },
  ],
  rows: [
    {
      name: 'FileCannotBeDownloaded',
      description: 'Impossible to download a remote file given its URL',
    },
    {
      name: 'FileMimeTypeCannotBeGuessed',
      description: 'File has been downloaded given its URL, but no MIME type could have been deduced',
    },
    {
      name: 'FileExtensionCannotBeGuessed',
      description: 'File has been downloaded given its URL, but no extension could have been deduced',
    },
    {
      name: 'FileIsTooLarge',
      description: 'File size is too high',
    },
    {
      name: 'FileExtensionIsNotAccepted',
      description: 'File extension is not authorized (in general, on the platform)',
    },
    {
      name: 'FileMimeTypeIsNotAccepted',
      description: 'File MIME type is not authorized (in general, on the platform)',
    },
    {
      name: 'EmptyFile',
      description: 'The file has no content (size to 0)',
    },
  ],
};

function FileSending() {
  return (
    <ApiSection 
      title="File sending"
      href="file-sending"
    >
      <Typography variant="body2">
        We herein illustrate the possible methods for providing files via your POST request body.
      </Typography>
      {
        // Preloaded-file ID
      }
      <Typography variant="h5" className="colorSecondary mt-2 mb-2 pt-2">
        Preloaded file
      </Typography>
      <Typography variant="body2" component="span">
        With this method, you use the endpoint <ShortCode>/upload/me</ShortCode> to send a file and get its ID in the response. You can use this ID later in another POST request.
        <br /><br />See <ShortCode>/upload/me</ShortCode> <a href="#reference-post-upload-a-file">dedicated section</a> for more details.
      </Typography>
      <Note status="success" className="mt-2" title={<React.Fragment><span role="img" aria-label="highlight">⭐️</span> Best practice</React.Fragment>}>
        <span className="mt-2 block">
          We encourage the use of this method because it gives you the way to parallel loading on your frontend. 
          <br /><br />If you have numerous file inputs in your form, using this method will save you from very long browser submits.
          <br />I.e. you load a file when the user fills the form (once loaded, you store the ID), and on submit, you send the ID instead of the user binary. 
          Our backend will immediately assign the related file (given its ID).
          <br /><br />Please note that the preloaded-file ID will be available and valid <span className="w-bold colorWarning">for the next 24 hours</span>, and will be expired afterwards.
        </span>
      </Note>
      {
        // Binary
      }
      <Typography variant="h5" className="colorSecondary mt-2 pt-2">
        Binary and <ShortCode>Content-Type: multipart/form-data</ShortCode> header
      </Typography>
      <CodeBlock codeKey="apiFileSendingBinaryExample" language="plaintext" title="Binary example" className="mt-2 mb-2" />
      {
        // Readable cloud URL
      }
      <Typography variant="h5" className="colorSecondary mt-2 pt-2 mb-1">
        Readable cloud URL
      </Typography>
      <Typography variant="body2" component="span">
        You could provide a remote file URL (of your own cloud, for instance), following the <ShortCode>file+http(s)://</ShortCode> format. For example, if you want to use the link <ShortCode>https://my.cloud/path/file.jpg</ShortCode>:
      </Typography>
      <CodeBlock codeKey="apiFileSendingReadableCloudUrlExample" language="plaintext" title="Readable cloud url example" className="mt-2 mb-2" />
      <Note status="info" title="Why add file+?">
        Adding <ShortCode light>file+</ShortCode> to the scheme (http or https) is <span className="w-bolder">required</span>, otherwise the field would be considered to be a mere string which should not be subject to any treatment, and would be understood as such. Please do not forget to send us the scheme, otherwise your url will not be accepted.
      </Note>
      {
        // Related errors
      }
      <Table 
        table={errorsTable} 
        className="mt-2"
        title={<span className="colorError w-bold">Related errors</span>}
        caption={(
          <Typography variant="body2">
            You may receive one of the following errors (<ShortCode light>meta.type</ShortCode>) in response:
          </Typography>
        )}
      />
    </ApiSection>
  );
}

export default FileSending;
